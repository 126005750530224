import { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import PageHeader from "components/page-header";
import Table from "components/table";
import Modal from "components/modal";
import Delete from "components/delete";
import AddEditDepartment from "components/forms/add-edit-department";
import { useTextFormatHook } from "hooks/text-formatter";
import { ApiConsumer } from "api/ApiConsumer";
import { Navigate, NavLink } from "react-router-dom";
import { API_ROUTES } from "routes/api";
import { GLOBAL } from "constants/global";
import { PORTAL } from "routes/portal";
import { APP_ID } from "config";
import * as Sentry from "@sentry/react";

export default function Departments() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID));
    let { normaliseTableData } = useTextFormatHook();
    const [departments, setDepartments] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditDepartment, setOpenAddEditDepartment] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);

    const pageHeaderButtons = [
        <button
            type="button"
            onClick={() => setOpenAddEditDepartment(true)}
            className={`button`}
        >
            <span className="button-icon">
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Add Department / Division
        </button>,
    ];

    const tableHeaders = [
        {
            key: "name",
            value: "Departments",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "main_correspondent",
            value: "Main Correspondent",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "designation",
            value: "Designation",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "email",
            value: "Email Address",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "contact_number",
            value: "Contact Number",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    <NavLink
                        to={`${PORTAL.SECTIONS_BY_DEPARTMENT}/${row.id}`}
                        className={`brand-link`}
                    >
                        {row.normalised.name}
                    </NavLink>
                    <Delete
                        apiRoute={API_ROUTES.METADATA.SECTION.DEPARTMENT_DETAIL}
                        rowData={row}
                        loadData={loadData}
                        titleValue={"Department / Division"}
                    />
                </>
            );
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.METADATA.SECTION.DEPARTMENTS)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setDepartments(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setDepartments({});
            setLoaded(false);
            setOpenAddEditDepartment(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    const onCloseAddEditDepartment = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditDepartment(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditDepartment(false)
            : setOpenAddEditDepartment(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== GLOBAL.USER_TYPES.ADMINISTRATOR)
        return <Navigate to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Department / Division`
                        : `Edit Department / Division: ${editableRow.name}`
                }
                modalContent={
                    <AddEditDepartment
                        setOpen={onCloseAddEditDepartment}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditDepartment}
                setOpen={onCloseAddEditDepartment}
            />

            <PageHeader
                pageHeaderName={`Departments (${departments?.length})`}
                pageHeaderButtons={pageHeaderButtons}
            />

            {loaded && (
                <Table
                    tableTitle={`Departments`}
                    tableHeaders={tableHeaders}
                    tableData={departments}
                    editable={true}
                    setEditableRow={setEditableRow}
                />
            )}
        </>
    );
}
