import { APP_NAME, APP_LOGO, APP_LOGO_ALT } from "config";

export default function PageHeaderExternal({ pageHeaderName }) {
    return (
        <>
            <div>
                <img
                    className="w-2/4 h-auto m-auto p-2 rounded-md mb-2 bg-white"
                    src={APP_LOGO}
                    alt={APP_LOGO_ALT}
                />
                <h2 className="mt-2 text-center text-2xl font-extrabold text-gray-900">
                    {APP_NAME}
                </h2>
                <p className="mt-2 text-center text-lg text-gray-600">
                    {pageHeaderName}
                </p>
            </div>
        </>
    );
}
