import { Fragment, useContext } from "react";
import { AppLoaderContext } from "contexts/app-loader";

export default function PageHeader({
    pageHeaderName,
    pageHeaderButtons = [],
    pageHeaderIcons = [],
}) {
    let { loading } = useContext(AppLoaderContext);
    return (
        <>
            <div className="bg-white">
                <div className="mx-full px-4 sm:px-6 lg:px-8">
                    <div className="py-4 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                        <div className="flex-1 min-w-0">
                            <div className="flex items-center">
                                <div>
                                    <div className="flex items-center">
                                        <h1 className="h1 whitespace-normal flex items-center">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: pageHeaderName,
                                                }}
                                            />
                                            {pageHeaderIcons.map(
                                                (button, i) => (
                                                    <Fragment key={i}>
                                                        {button}
                                                    </Fragment>
                                                )
                                            )}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pageHeaderButtons !== undefined && !loading && (
                            <div className="flex space-x-2">
                                {pageHeaderButtons.map((button, i) => (
                                    <Fragment key={i}>{button}</Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
