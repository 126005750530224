import { Routes, Route, Outlet } from 'react-router-dom'
import { PORTAL } from 'routes/portal'
/* import Admin from 'pages/admin' */
import Departments from 'pages/departments'
import Users from 'pages/users'
import Meta from 'pages/meta'
import Activity from 'pages/activity'
import Settings from 'pages/settings'

export default function AdminRoutes() {
  return (
    <>
      <Routes>
        {/* <Route index element={<Admin />} /> */}

        <Route
          path={PORTAL.DEPARTMENTS.replace(PORTAL.ADMIN, '')}
          element={<Departments />}
        />
        <Route
          path={PORTAL.USERS.replace(PORTAL.ADMIN, '') + `/administrators`}
          element={<Users />}
        />
        <Route
          path={PORTAL.USERS.replace(PORTAL.ADMIN, '')}
          element={<Users />}
        />
        <Route
          path={PORTAL.META.replace(PORTAL.ADMIN, '')}
          element={<Meta />}
        />
        <Route
          path={PORTAL.ACTIVITY.replace(PORTAL.ADMIN, '')}
          element={<Activity />}
        />
        <Route
          path={PORTAL.SETTINGS.replace(PORTAL.ADMIN, '')}
          element={<Settings />}
        />
      </Routes>
      <Outlet />
    </>
  )
}
