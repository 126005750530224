import { useState, useEffect, useContext } from "react";
import { useFormValidation } from "hooks/form-validation";
import { API_ROUTES } from "routes/api";
import { ApiConsumer } from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import Alert from "components/alert";
import { AppLoaderContext } from "contexts/app-loader";

export default function AddEditAuditFindings({
    kpi,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        { name: "description", display: "finding description" },
        { name: "legislation_contravened", display: "legislation contravened" },
        ,
        { name: "possible_impact", display: "possible impact" },
    ];

    const validateAddEditAuditFinding = () => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            console.log(formDetails);

            ApiConsumer.post(API_ROUTES.AUDIT.FINDINGS, formDetails)
                .then((res) => {
                    setRowUpdated(true);
                    setFormSubmitted(true);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    setFormError(err.toString());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        /* Defaults */

        setFormDetails((prevState) => ({
            ...prevState,
            [`description`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`legislation_contravened`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`possible_impact`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator`]: editableRow?.id,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`kpa`]: kpi?.kpa?.id,
        }));

        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditAuditFinding();
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="description"
                                >
                                    Finding description:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="description"
                                    name="description"
                                    autoComplete="description"
                                    className={`form-field ${
                                        invalidFormDetails.description
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`description`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.description
                                            ? formDetails.description
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="legislation_contravened"
                                >
                                    Legislation contravened:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="legislation_contravened"
                                    name="legislation_contravened"
                                    autoComplete="legislation_contravened"
                                    className={`form-field ${
                                        invalidFormDetails.legislation_contravened
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`legislation_contravened`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.legislation_contravened
                                            ? formDetails.legislation_contravened
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="possible_impact"
                                >
                                    Possible impact:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="possible_impact"
                                    name="possible_impact"
                                    autoComplete="possible_impact"
                                    className={`form-field ${
                                        invalidFormDetails.possible_impact
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`possible_impact`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.possible_impact
                                            ? formDetails.possible_impact
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                Add Audit Finding
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully added audit finding`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
