import TIDInformation from "components/tid-information";

export default function AddEditTIDInformation({ type, obj, route, quarter }) {
    return (
        <TIDInformation
            type={type}
            route={route}
            obj={obj}
            quarter={quarter}
        />
    );
}
