import { createClient } from '@supabase/supabase-js'

//TODO: Need to move these to an env var but only when we deploy. Not sure where or how we going to deploy as yet.
export const superBaseKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyOTg4NTQ0NiwiZXhwIjoxOTQ1NDYxNDQ2fQ.nYCvIjlNF0NfrqxZhKcG9tZrWRrj5lqf8xDx4iGP1RE'
export const superBaseUrl = 'https://jgxaekxogkdrnrdtioor.supabase.co'

const notificationService = createClient(superBaseUrl, superBaseKey)

export { notificationService }
