import { useEffect, useState, Fragment, useContext } from "react";
import { PORTAL } from "routes/portal";
import { NavLink, useMatch, useLocation } from "react-router-dom";
import { isAfter } from "date-fns";
import {
    PencilIcon,
    PlusCircleIcon,
    ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { compareDesc } from "date-fns";
import { useTextFormatHook } from "hooks/text-formatter";
import { usePermissionsHook } from "hooks/permissions";
import { AuthenticationContext } from "contexts/authentication";
import PageHeader from "components/page-header";
import TableHeader from "components/table-header";
import Card from "components/card";
import AddEditTask from "components/forms/add-edit-task";
import Modal from "components/modal";
import Alert from "components/alert";
import AddEditProject from "components/forms/add-edit-project";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import DownloadAsPdf from "components/share/download-pdf";
import { GLOBAL } from "constants/global";
import { useAuthentication } from "hooks/authentication";
import { PERMISSIONS } from "constants/permissions";
import { AppLoader } from "components/loader/app-loader";
import { AppLoaderContext } from "contexts/app-loader";

export default function Tasks() {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("search");
    let { setLoading } = useContext(AppLoaderContext);

    /* Tasks by Project */
    const tasksByProject = useMatch(PORTAL.TASKS_BY_PROJECT + "/:project_id");
    let projectId = tasksByProject?.params?.project_id;

    /* Tasks by user */
    const tasksByUser = useMatch(PORTAL.TASKS_BY_USER + "/:user_id");
    let userId = tasksByUser?.params?.user_id;

    /* Set task API url */
    let taskAPIURL = API_ROUTES.TASKS.TASKS;
    if (projectId) taskAPIURL = API_ROUTES.TASKS.TASKS_BY_PROJECT(projectId);
    if (userId) taskAPIURL = API_ROUTES.TASKS.TASKS_BY_USER(userId);

    let { normaliseTableData } = useTextFormatHook();

    let { hasPermission } = useAuthentication();
    const [project, setProject] = useState({});
    const [tasks, setTasks] = useState([]);
    const [user, setUser] = useState({});
    const [openAddEditTask, setOpenAddEditTask] = useState(false);
    const [openAddEditProject, setOpenAddEditProject] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [editableProject, setEditableProject] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [filterString, setFilterString] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const pageHeaderButtons = [];

    const pageHeaderIcons = [
        !project.closed &&
            hasPermission(PERMISSIONS.PROJECTS.CAN_EDIT_PROJECT) && (
                <button
                    type="button"
                    className="icon-transparent bg-gray-100 ml-3"
                >
                    <PencilIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        onClick={() => {
                            setEditableProject(project);
                            setOpenAddEditProject(true);
                        }}
                    />
                </button>
            ),
        <DownloadAsPdf
            tableTitle={`Project:`}
            tableHeaders={GLOBAL.PROJECT_HEADERS}
            tableData={[project]}
            tableDataResult={project?.name}
            printType={GLOBAL.PRINT.PAGE}
            filename={`Project: ${project?.name}`}
        />,
    ];
    const subHeaderButtons = [ <NavLink
        to={`${PORTAL.PROJECT}/${project?.id}/`}
        className={`button-default`}
    >
        <span className="button-icon">
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </span>
        Back to Project
    </NavLink>,
        !project.closed &&
        !userId &&
        hasPermission(PERMISSIONS.TASKS.CAN_ADD_TASK) ? (
            <button
                type="button"
                onClick={() => setOpenAddEditTask(true)}
                className={`button`}
            >
                <span className="button-icon">
                    <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                Add Task
            </button>
        ) : null,
       
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.TASK}/${row.id}`}
                    className={`brand-link underline`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const loadData = () => {
        setLoading(true);
        ApiConsumer.get(taskAPIURL)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.created), new Date(b.created))
                );
                if (
                    taskAPIURL === API_ROUTES.TASKS.TASKS &&
                    searchTerm !== null &&
                    searchTerm !== ""
                ) {
                    let tasks = res.data;
                    res.data = tasks.filter((p) =>
                        p.name.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                }

                let normalisedData = normaliseTableData(
                    GLOBAL.TASK_HEADERS,
                    res.data
                );
                console.log(res.data);

                setTasks(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setProject({});
            setTasks([]);
            setUser({});
            setOpenAddEditTask(false);
            setOpenAddEditProject(false);
            setEditableRow({});
            setEditableProject({});
            setRowUpdated(false);
            setFilterString("");
            setFilteredData([]);
        };
    }, [taskAPIURL, projectId, userId]);

    useEffect(() => {
        if (userId) {
            ApiConsumer.get(API_ROUTES.USERS.USER_DETAIL(userId))
                .then((res) => {
                    setUser(res.data);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }

        if (projectId) {
            ApiConsumer.get(API_ROUTES.PROJECT.PROJECT_DETAIL(projectId))
                .then((res) => {
                    setProject(
                        normaliseTableData(GLOBAL.PROJECT_HEADERS, [
                            res.data,
                        ])[0]
                    );
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }
    }, [userId, projectId, taskAPIURL]);

    const onCloseAddEditTask = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditTask(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditTask(false)
            : setOpenAddEditTask(true);
    }, [editableRow]);

    const onCloseAddEditProject = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableProject({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditProject(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableProject).length === 0
            ? setOpenAddEditProject(false)
            : setOpenAddEditProject(true);
    }, [editableProject]);

    return (
        <>
            <Modal
                modalHeaderTag={
                    project?.kpa?.name !== undefined
                        ? `KPA: ` + project.kpa.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableProject).length === 0
                        ? `Add New Project`
                        : `Edit Project: ${editableProject.name} (${editableProject.year})`
                }
                modalContent={
                    <AddEditProject
                        year={
                            editableRow?.year !== undefined
                                ? editableRow.year
                                : project?.year
                        }
                        kpaId={
                            editableProject?.kpa?.id !== undefined
                                ? editableProject.kpa.id
                                : project?.kpa?.id
                        }
                        kpi={project?.indicator}
                        setOpen={onCloseAddEditProject}
                        editableRow={editableProject}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditProject}
                setOpen={onCloseAddEditProject}
            />

            <Modal
                modalHeaderTag={
                    project?.programme?.name !== undefined
                        ? `PROJECT: ` + project.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Task`
                        : `Edit Task: ${editableRow.name}`
                }
                modalContent={
                    <AddEditTask
                        project={project}
                        setOpen={onCloseAddEditTask}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditTask}
                setOpen={onCloseAddEditTask}
            />
            
            {!userId && (searchTerm === null || searchTerm === "") && (
                <PageHeader
                    pageHeaderName={`${
                        project?.name !== undefined
                            ? `Project: ` + project.name
                            : ""
                    } 
                     <span class="header-tag-red">
                ${project?.year !== undefined ? project.year : ""}
              </span>
              <span class="header-tag-red">
                ${project?.kpa?.name !== undefined ? project.kpa.name : ""}
              </span>`}
                    pageHeaderButtons={pageHeaderButtons}
                    pageHeaderIcons={pageHeaderIcons}
                />
            )}
            <PageHeader
                pageHeaderName={`${
                    user?.first_name !== undefined &&
                    user?.last_name !== undefined
                        ? user.first_name + " " + user.last_name + " "
                        : ""
                }Tasks (${tasks?.length}) ${
                    searchTerm !== null && searchTerm !== ""
                        ? ' Search results for "' + searchTerm + '"'
                        : ""
                }`}
                pageHeaderButtons={subHeaderButtons}
            />
            {hasPermission(PERMISSIONS.PROJECTS.CAN_VIEW_PROJECT) ? (
                <div className="relative">
                    <AppLoader
                        pageContent={
                            tasks.length ? (
                                <>
                                    <TableHeader
                                        tableTitle={`${
                                            project?.name !== undefined
                                                ? project.name + " - "
                                                : ""
                                        }${
                                            user?.first_name !== undefined &&
                                            user?.last_name !== undefined
                                                ? user.first_name +
                                                  " " +
                                                  user.last_name +
                                                  " "
                                                : ""
                                        }Task List`}
                                        tableHeaders={GLOBAL.TASK_HEADERS}
                                        tableData={tasks}
                                        filterString={filterString}
                                        setFilterString={setFilterString}
                                        filteredData={filteredData}
                                        setFilteredData={setFilteredData}
                                    />

                                    <div className="mx-full px-4 sm:px-6 lg:px-8 my-5">
                                        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                            {(filterString.length ||
                                            filteredData.length
                                                ? filteredData
                                                : tasks
                                            ).map((task, i) => (
                                                <Fragment key={i}>
                                                    <Card
                                                        row={task}
                                                        editable={true}
                                                        setEditableRow={
                                                            setEditableRow
                                                        }
                                                        reloadTaskData={
                                                            loadData
                                                        }
                                                        tableHeaders={
                                                            GLOBAL.TASK_HEADERS
                                                        }
                                                    />
                                                </Fragment>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            ) : (
                                <div className="mx-full px-8 my-4 text-center">
                                    <Alert
                                        type={`danger`}
                                        message={`There are no tasks added under ${
                                            project?.name !== undefined
                                                ? project.name
                                                : ""
                                        } ${
                                            user?.first_name !== undefined &&
                                            user?.last_name !== undefined
                                                ? user.first_name +
                                                  " " +
                                                  user.last_name
                                                : ""
                                        }
                          ${
                              searchTerm !== null && searchTerm !== ""
                                  ? searchTerm
                                  : ""
                          }`}
                                    />
                                </div>
                            )
                        }
                    />
                </div>
            ) : (
                <div className="mx-full px-8 my-4 text-center">
                    <Alert
                        type={`danger`}
                        message={`You do not have permission to view tasks. Please contact the system administrator.`}
                    />
                </div>
            )}
        </>
    );
}
