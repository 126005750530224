import { useEffect, useState, useContext } from "react";
import { useFormValidation } from "hooks/form-validation";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import { format, compareDesc } from "date-fns";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { AuthenticationContext } from "contexts/authentication";
import Delete from "components/delete";
import Alert from "components/alert";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import { AppLoader } from "components/loader/app-loader";
import { AppLoaderContext } from "contexts/app-loader";

export default function Attachments({ type, route, id, indicator=null }) {
    let { setLoading } = useContext(AppLoaderContext);
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        { name: "quarter", display: "quarter" },
        { name: "name", display: "attachment name" },
        { name: "attachment", display: "attachment" },
    ];
    const validateAddAttachment = () => {
        setFormSubmitted(false);
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            let formData = new FormData();
            for (const key in formDetails) {
                formData.append(key, formDetails[key]);
            }
            formData.append("attachment", formDetails.attachment);

            ApiConsumer.post(API_ROUTES.ATTACHMENTS.ATTACHMENTS, formData)
                .then((res) => {
                    loadAttachments();
                    setFormSubmitted(true);
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`quarter`]: "selected",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`name`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`attachment`]: "",
                    }));
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    setFormError(err.toString());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const loadAttachments = () => {
        ApiConsumer.get(route(id))
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                setAttachments(res.data);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    };

    useEffect(() => {
        loadAttachments();
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [type]: id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`attachment`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`uploaded_by`]: currentUser.id,
        }));
        return () => {
            setAttachments([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [id, currentUser.id]);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />

            <div className="mb-2" >
            {indicator!==null && 
            <Alert
                type={`success`}
                message={`<strong>EVIDENCE REQUIRED</strong>: `+indicator.evidence}
            />}
            </div>

            {type === "task" && (
                <>
                    <h2
                        id="timeline-title"
                        className="text-lg font-medium text-gray-900"
                    >
                        <strong>
                            General Attachments ({attachments.length})
                        </strong>
                    </h2>
                    <p className="text-sm">
                        <span className="font-bold">Please do not</span> attach
                        any TID/POE reports under this section.
                    </p>
                </>
            )}

            <form
                className="space-y-1 relative"
                onSubmit={(event) => {
                    event.preventDefault();
                    validateAddAttachment();
                }}
                method="POST"
                encType="multipart/form-data"
            >
                <AppLoader
                    pageContent={
                        <>
                            <div className="grid grid-cols-12">
                                <div className="col-span-4 pt-1">
                                    <label
                                        className="text-sm"
                                        htmlFor="quarter"
                                    >
                                        Quarter:{" "}
                                        <span className="text-red-600">*</span>
                                    </label>
                                </div>
                                <div className="col-span-8">
                                    <select
                                        id="quarter"
                                        name="quarter"
                                        autoComplete="quarter"
                                        className={`${
                                            invalidFormDetails.quarter
                                                ? `border-red-600`
                                                : `border-gray-300`
                                        }`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`quarter`]: event.target.value,
                                            }));
                                        }}
                                        value={
                                            formDetails.quarter
                                                ? formDetails.quarter
                                                : "selected"
                                        }
                                    >
                                        <option value="selected">
                                            Select Quarter
                                        </option>
                                        <option value="1">Quarter 1</option>
                                        <option value="2">Quarter 2</option>
                                        <option value="3">Quarter 3</option>
                                        <option value="4">Quarter 4</option>
                                        <option value="0">
                                            Annual/Overall
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                autoComplete="name"
                                placeholder={`${
                                    type === "task" ? "Attachment" : "POE"
                                } name`}
                                className={`form-field ${
                                    invalidFormDetails.name
                                        ? `border-red-600`
                                        : `border-gray-300`
                                }`}
                                onChange={(event) => {
                                    setFormDetails((prevState) => ({
                                        ...prevState,
                                        [`name`]: event.target.value,
                                    }));
                                    setFormSubmitted(false);
                                }}
                                value={formDetails.name ? formDetails.name : ""}
                            />
                            <input
                                id="attachment"
                                name="attachment"
                                autoComplete="attachment"
                                className={`form-field ${
                                    invalidFormDetails.attachment
                                        ? `border-red-600`
                                        : `border-gray-300`
                                }`}
                                onChange={(event) => {
                                    setFormDetails((prevState) => ({
                                        ...prevState,
                                        [`attachment`]: event.target.files[0],
                                    }));
                                    setFormSubmitted(false);
                                }}
                                //value={formDetails.attachment}
                                type="file"
                            />

                            {formError.length !== 0 && (
                                <div className="col-span-12 form-error text-right">
                                    {formError}
                                </div>
                            )}
                            {formSubmitted && (
                                <Alert
                                    type={`success`}
                                    message={`Successfully uploaded.`}
                                />
                            )}

                            <button
                                type="submit"
                                className="button float-right"
                            >
                                Upload
                            </button>
                            <div className="clear-both"></div>
                        </>
                    }
                />
            </form>

            <div className="mt-2 flow-root">
                <dd className="mt-1 text-sm text-gray-900">
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                        {attachments.length ? (
                            attachments.map((attachment, i) => (
                                <li key={i} className="pl-3 pr-4 py-3 text-sm">
                                    <PaperClipIcon
                                        className="h-5 w-5 float-left"
                                        aria-hidden="true"
                                    />
                                    <a
                                        href={attachment.attachment}
                                        target="_blank"
                                        className="brand-link ml-1 truncate float-left"
                                    >
                                        {attachment.name}
                                    </a>
                                    <span className="text-sm ml-1">
                                        (For{" "}
                                        {parseInt(attachment.quarter) === 0
                                            ? `Annual/Overall`
                                            : `Quarter ` + attachment.quarter}
                                        )
                                    </span>
                                    {attachment.uploaded_by.id ===
                                        currentUser.id && (
                                        <Delete
                                            apiRoute={
                                                API_ROUTES.ATTACHMENTS
                                                    .ATTACHMENT_DETAIL
                                            }
                                            rowData={attachment}
                                            loadData={loadAttachments}
                                            titleValue={"Attachment"}
                                        />
                                    )}
                                    <div className="clear-both"></div>
                                    <div className="mt-0">
                                        <span className="text-gray-400 text-sm">
                                            By{" "}
                                            <span
                                                className="hover:underline cursor-pointer"
                                                onClick={() => {
                                                    setUserDetailsRow(
                                                        attachment.uploaded_by
                                                    );
                                                    setOpenUserDetails(true);
                                                }}
                                            >
                                                {attachment.uploaded_by
                                                    .first_name +
                                                    " " +
                                                    attachment.uploaded_by
                                                        .last_name}
                                            </span>{" "}
                                            {format(
                                                new Date(attachment.created),
                                                "dd MMM yyyy HH:mm:ss"
                                            )}
                                        </span>
                                    </div>
                                    <div className="clear-both"></div>
                                </li>
                            ))
                        ) : (
                            <div className="pl-3 pr-4 py-3 text-sm">
                                There are no{" "}
                                {type === "task" ? "Attachment" : "POE"}{" "}
                                uploaded.
                            </div>
                        )}
                    </ul>
                </dd>
            </div>
        </>
    );
}
