import { Fragment, useState, useEffect, useContext } from "react";
import { format, isAfter, addYears, subDays, addDays } from "date-fns";
import { useFormValidation } from "hooks/form-validation";
import { API_ROUTES } from "routes/api";
import { GLOBAL } from "constants/global";
import { ApiConsumer } from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import Alert from "components/alert";
import { AppLoaderContext } from "contexts/app-loader";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AddEditTerm({ setOpen, editableRow, setRowUpdated }) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();

    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        { name: "year1_start_date", display: "Year 1 start date" },
        { name: "year1_end_date", display: "Year 1 end date" },
        { name: "year2_start_date", display: "Year 2 start date" },
        { name: "year2_end_date", display: "Year 2 end date" },
        { name: "year3_start_date", display: "Year 3 start date" },
        { name: "year3_end_date", display: "Year 3 end date" },
        { name: "year4_start_date", display: "Year 4 start date" },
        { name: "year4_end_date", display: "Year 4 end date" },
        { name: "year5_start_date", display: "Year 5 start date" },
        { name: "year5_end_date", display: "Year 5 end date" },
    ];

    const validateAddEditTerm = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            console.log(formDetails);
            setLoading(true);
            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.CONTENT.TERM_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.CONTENT.TERM, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const updateYears = (event) => {
        GLOBAL.TERMS.map((year, i) => {
            setFormDetails((prevState) => ({
                ...prevState,
                [`year` + year + `_start_date`]: addYears(event, year - 1),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year` + year + `_end_date`]: subDays(
                    addYears(event, year),
                    1
                ),
            }));
        });
    };

    useEffect(() => {
        /* Defaults */
        updateYears(new Date());

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`term`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year1_start_date`]: new Date(editableRow.year1_start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year1_end_date`]: new Date(editableRow.year1_end_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year2_start_date`]: new Date(editableRow.year2_start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year2_end_date`]: new Date(editableRow.year2_end_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year3_start_date`]: new Date(editableRow.year3_start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year3_end_date`]: new Date(editableRow.year3_end_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year4_start_date`]: new Date(editableRow.year4_start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year4_end_date`]: new Date(editableRow.year4_end_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year5_start_date`]: new Date(editableRow.year5_start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`year5_end_date`]: new Date(editableRow.year5_end_date),
            }));
        }

        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditTerm(Object.keys(editableRow).length);
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <>
                            <Modal
                                modalHeaderTag={""}
                                modalHeader={`User Details: ${editableRow.created_by.first_name} ${editableRow.created_by.last_name}`}
                                modalContent={
                                    <ModalUserDetails
                                        setOpen={setOpenUserDetails}
                                        userDetailsRow={userDetailsRow}
                                    />
                                }
                                open={openUserDetails}
                                setOpen={setOpenUserDetails}
                            />
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                                by{" "}
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(
                                            editableRow.created_by
                                        );
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {editableRow.created_by.first_name +
                                        " " +
                                        editableRow.created_by.last_name}
                                </span>{" "}
                                <span className="font-bold">Last updated:</span>{" "}
                                {editableRow.normalised.updated}
                            </div>
                        </>
                    )}
                    <div className="space-y-2">
                        {GLOBAL.TERMS.map((year, i) => (
                            <Fragment key={i}>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-2">
                                        <label
                                            className="text-sm"
                                            htmlFor={
                                                `year` + year + `_start_date`
                                            }
                                        >
                                            Year {year}:{" "}
                                            <span className="text-red-600">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-span-4">
                                        <DatePicker
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            showYearDropdown
                                            dateFormat="dd MMMM yyyy"
                                            selected={
                                                formDetails[
                                                    `year` +
                                                        year +
                                                        `_start_date`
                                                ]
                                            }
                                            onChange={(event) => {
                                                updateYears(event);
                                            }}
                                            className="form-field"
                                            disabled={year === 1 ? false : true}
                                        />
                                    </div>
                                    <div className="col-span-2 text-center">
                                        {" "}
                                        -{" "}
                                    </div>
                                    <div className="col-span-4">
                                        <DatePicker
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            showYearDropdown
                                            dateFormat="dd MMMM yyyy"
                                            selected={
                                                formDetails[
                                                    `year` + year + `_end_date`
                                                ]
                                            }
                                            className="form-field"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ))}

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Term`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } term.`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
