import { GLOBAL } from "constants/global";

export default function Status({ progressValue }) {
    return (
        <span>
            Status{" "}
            <span
                className={`inline-flex uppercase items-center px-2 py-0.5 rounded font-medium tag ${
                    GLOBAL.SLIDER.filter((x) =>
                        progressValue <= 100
                            ? x.MIN <= progressValue && x.MAX >= progressValue
                            : x.MAX > 100
                    )[0].STYLE
                } text-white text-xs`}
            >
                {
                    GLOBAL.SLIDER.filter((x) =>
                        progressValue <= 100
                            ? x.MIN <= progressValue && x.MAX >= progressValue
                            : x.MAX > 100
                    )[0].NAME
                }
            </span>{" "}
        </span>
    );
}
