import { APP_NAME, APP_LOGO } from "config";
import { format } from "date-fns";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { GLOBAL } from "constants/global";

export default function DownloadAsPdf({
    tableTitle,
    tableHeaders,
    tableData,
    tableDataResult,
    printType,
    filename = "",
    downloadIconText = ""
}) {
    const numberFormatter = new Intl.NumberFormat("en-US");
    const generateData = () => {
        let printResults = [];
        let printData = [...tableData];

        if (printData.name)
            printData.sort((a, b) => a.name.localeCompare(b.name));

        printData.forEach((data, i) => {
            let normalised = { ...data.normalised };
            normalised["#"] = i + 1;
            Object.entries(normalised).forEach(([key, value]) => {
                if (
                    tableHeaders.filter(
                        (header) =>
                            header.key === key && header.showOnPrint === false
                    ).length !== 0
                ) {
                    delete normalised[key];
                    return false;
                }

                if (value === null || value === undefined)
                    normalised[key] = "-";
                else if (typeof value === "object"){
                    if (key==='kpa' || key==='project')
                        normalised[key] = data[key].name
                    else if (key==='kpi')
                        normalised[key] = data[key].indicator_name
                    else
                        normalised[key] = data[key].toString(); //Take original
                    
                }
                //Format Array
                if (Array.isArray(value)) normalised[key] = value.join(", ");
                //Format `Closed` as part of name
                if (key === "name") {
                    normalised["name"] += data.closed ? ` (CLOSED)` : ``;
                    let projectSuffix =
                        data?.total_projects && data?.total_projects === 1
                            ? ` project`
                            : ` projects`;
                    normalised["name"] += data?.total_projects
                        ? ` (` + data?.total_projects + projectSuffix + `)`
                        : ``;
                }
            });

            printResults.push(normalised);
        });

        return printResults;
    };

    const createHeaders = () => {
        let printHeaders = [];

        if (printType === GLOBAL.PRINT.TABLE)
            printHeaders.push({
                dataKey: "#",
                header: "#",
            });

        tableHeaders
            .filter((header) => header.showOnPrint === true)
            .forEach((header) => {
                printHeaders.push({
                    dataKey: header.key,
                    header: header.value,
                });
            });
        return printHeaders;
    };

    const downloadAsPdf = () => {
        let colHeaders = createHeaders();
        let colData = generateData();

        let image = new Image();
        image.src = APP_LOGO;
        const pdf = new jsPDF({
            orientation:
                printType === GLOBAL.PRINT.TABLE ? "landscape" : "portrait",
        });
        const pdfWidth = pdf.internal.pageSize.getWidth();

        pdf.setFontSize(8);
        pdf.text(
            `${APP_NAME} - Downloaded ${format(new Date(), "dd MMMM yyyy")}`,
            13,
            11
        );

        if (printType === GLOBAL.PRINT.TABLE) {
            pdf.setFontSize(14);
            pdf.text(`${tableTitle}`, 13, 19);
            pdf.setFontSize(8);
            pdf.text(`${tableDataResult}`, 13, 24);
        }
        if (printType === GLOBAL.PRINT.PAGE) {
            pdf.setFontSize(12);
            pdf.text(`${tableTitle}`, 13, 18);
            pdf.setFontSize(12);
            pdf.text(`${tableDataResult}`, 13, 24);

            let printHeaders = [
                { dataKey: "name", header: "Name" },
                { dataKey: "value", header: "Value" },
            ];
            let printResults = [];
            colData.forEach((data) => {
                colHeaders.forEach((colHeader) => {
                    printResults.push({
                        name: colHeader.header,
                        value: data[colHeader.dataKey].toString(),
                    });
                });
            });
            colHeaders = printHeaders;
            colData = printResults;
        }
        pdf.setFontSize(8);

        autoTable(pdf, {
            margin: { left: 13, top: 30 },
            columns: colHeaders,
            body: colData,
            styles: { fontSize: 8 },
            headStyles: { fillColor: [22, 163, 74] },
            didParseCell: (data) => {
                console.log(data);
                if (
                    data.section === "body" &&
                   (data.column.dataKey === "overall_progress" || data.column.dataKey === "progress")
                ) {
                    data.cell.text =
                        numberFormatter.format(data.cell.text[0]) +
                        `% ${
                            `(` +
                            GLOBAL.SLIDER.filter((x) =>
                                data.cell.raw <= 100
                                    ? x.MIN <= data.cell.raw &&
                                      x.MAX >= data.cell.raw
                                    : x.MAX > 100
                            )[0].NAME +
                            `)`
                        }`;
                }
            },
            columnStyles:
                printType === GLOBAL.PRINT.PAGE ? { 0: { cellWidth: 50 } } : {},
        });

        pdf.addImage(image, "PNG", pdfWidth - 30, 7, 15, 18);
        pdf.save(filename !== "" ? filename : tableTitle);
    };

    return (
        <button type="button" className="icon-transparent bg-gray-100">
            {downloadIconText? 
            <div className="m-2 text-black" onClick={() => downloadAsPdf()}>
                <DocumentArrowDownIcon
                    className="h-6 w-6 float-left"
                    aria-hidden="true"
                />{downloadIconText}</div> :
            <DocumentArrowDownIcon
                onClick={() => downloadAsPdf()}
                className="h-6 w-6"
                aria-hidden="true"
            />
            }
        </button>
    );
}
