import { useEffect, useState } from 'react'
import { ApiConsumer } from 'api/ApiConsumer'
import { API_ROUTES } from 'routes/api'
import { compareDesc, format } from 'date-fns'
import PageHeader from 'components/page-header'
import Table from 'components/table'
import { useTextFormatHook } from 'hooks/text-formatter'
import { PORTAL } from 'routes/portal'
import { APP_ID } from 'config'
import { Navigate } from 'react-router-dom'
import { GLOBAL } from 'constants/global'
import ModalUserDetails from 'components/modal-user-details'
import Modal from 'components/modal'
import * as Sentry from '@sentry/react'

export default function Settings() {
  let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID))

  const [activity, setActivity] = useState([])
  const [loaded, setLoaded] = useState(false)
  const pageHeaderButtons = []

  if (loggedInUser.user_type.name !== GLOBAL.USER_TYPES.ADMINISTRATOR)
    return <Navigate to={PORTAL.LOGIN} />

  return (
    <>
      <PageHeader
        pageHeaderName={`Admin Settings`}
        pageHeaderButtons={pageHeaderButtons}
      />
    </>
  )
}
