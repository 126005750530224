import { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import PageHeader from "components/page-header";
import Table from "components/table";
import { useTextFormatHook } from "hooks/text-formatter";
import { useDataLoaderHook } from "hooks/data-loader";
import { ApiConsumer } from "api/ApiConsumer";
import { NavLink } from "react-router-dom";
import { GLOBAL } from "constants/global";
import { API_ROUTES } from "routes/api";
import { PORTAL } from "routes/portal";
import { AppLoader } from "components/loader/app-loader";
import { AppLoaderContext } from "contexts/app-loader";

import Modal from "components/modal";
import Alert from "components/alert";
import AddEditComments from "components/forms/add-edit-comments";
import AddEditChallenges from "components/forms/add-edit-challenges";

export default function Reports() {
    let { normaliseTableData } = useTextFormatHook();
    const [tasks, setTasks] = useState([]);

    const [aprReport, setAprReport] = useState([]);

    const [openAddEditChallenges, setOpenAddEditChallenges] = useState(false);
    const [editableChallenges, setEditableChallenges] = useState({});
    const [openAddEditComments, setOpenAddEditComments] = useState(false);
    const [editableComments, setEditableComments] = useState({});

    const aprTableHeaders = [
        {
            key: "kpa",
            value: "KPA",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "kpi",
            value: "KPI",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "project",
            value: "Project",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "programme",
            value: "Section/Group",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "departments",
            value: "Department / Division:",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "project_idp_ref",
            value: "IDP Ref No",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "project_budget",
            value: "Draft Budget",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "project_second_budget",
            value: "2nd Draft Budget:",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "project_actual_budget",
            value: "Final Budget",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "expenditure",
            value: "Expenditure",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "indicator_umo",
            value: "UMO",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "baseline",
            value: "Baseline (Previous Year)",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "target",
            value: "Annual Target (Current Year)",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "actual",
            value: "Actual",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "variances",
            value: "Variances",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "q1_target",
            value: "Q1 Target",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "q1_actual",
            value: "Q1 Actual",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "q2_target",
            value: "Q2 Target",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "q2_actual",
            value: "Q2 Actual",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "q3_target",
            value: "Q3 Target",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "q3_actual",
            value: "Q3 Actual",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "q4_target",
            value: "Q4 Target",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "q4_actual",
            value: "Q4 Actual",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "progress",
            value: "Project Progress",
            showOnTable: true,
            showOnPrint: true,
        },
        /* {
            key: "status",
            value: "Project Status",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: false,
        }, */
    ];

    const addTaskLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.TASK}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );

            row.normalised.project = (<>
                <NavLink
                    to={`${PORTAL.PROJECT}/${row.project.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.project}
                </NavLink>
                <button
                type="button"
                onClick={() => {
                    setEditableChallenges(row.project);
                    setOpenAddEditChallenges(true);
                }}
                className={`button-default mb-2 w-full`}
                >
                Challenges
                </button>

                <button
                target="_blank"
                className={`button-default mb-2 w-full`}
                onClick={() => {
                    setEditableComments(row.project);
                    setOpenAddEditComments(true);
                }}
                >
                Comments
                </button>
                
                </>
                
                );

            row.normalised.kpa = (
                <NavLink
                    to={`${PORTAL.SDBIP_KPIS_BY_KPA}/${row.kpa.id}/${row.kpa.term.id}/2024`}
                    className={`brand-link`}
                >
                    {row.normalised.kpa}
                </NavLink>
            );

            row.normalised.kpi = (
                <NavLink
                    to={`${PORTAL.PROJECTS}/2024/${row.kpa.id}/${row.kpi.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.kpi}
                </NavLink>
            );
        });
        return tableData;
    };

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.TASKS.TASKS).then((res) => {
            res.data = res.data.filter((task) => task.project.active === true);
            let normalisedData = normaliseTableData(aprTableHeaders, res.data);

            setTasks(addTaskLinksToTableData(normalisedData));
        });

        return () => {
            setTasks([]);
        };
    }, []);



    const onCloseAddEditComments = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableComments({});
        }
        setOpenAddEditComments(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableComments).length === 0
            ? setOpenAddEditComments(false)
            : setOpenAddEditComments(true);
    }, [editableComments]);

 

    const onCloseAddEditChallenges = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableChallenges({});
        }
        setOpenAddEditChallenges(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableChallenges).length === 0
            ? setOpenAddEditChallenges(false)
            : setOpenAddEditChallenges(true);
    }, [editableChallenges]);

    return (
        <>
        <Modal
                modalHeaderTag={`PROJECT: ` +editableChallenges?.name}
                modalHeader={`Project Challenges`}
                modalContent={
                    <AddEditChallenges
                        type="project"
                        obj={editableChallenges}
                        route={API_ROUTES.CHALLENGES.PROJECTS}
                    />
                }
                open={openAddEditChallenges}
                setOpen={onCloseAddEditChallenges}
            />
            <Modal
                modalHeaderTag={`PROJECT: ` + editableComments?.name}
                modalHeader={`Project Comments`}
                modalContent={
                    <AddEditComments
                        type="project"
                        obj={editableComments}
                        route={API_ROUTES.COMMENTS.PROJECTS}
                    />
                }
                open={openAddEditComments}
                setOpen={onCloseAddEditComments}
            />
            <PageHeader pageHeaderName={`Annual Performance Report (APR)`} />
            {
                <div className="relative">
                    <AppLoader
                        pageContent={
                            tasks.length ? (
                                <Table
                                    tableTitle={`Annual Performance Report (APR)`}
                                    tableHeaders={aprTableHeaders}
                                    tableData={tasks}
                                    editable={false}
                                    setEditableRow={{}}
                                />
                            ) : (
                                <div className="mx-full px-8 my-4 text-center">
                                    <Alert
                                        type={`danger`}
                                        message={`There is no sufficient data to produce the Annual Performance Report (APR)"`}
                                    />
                                </div>
                            )
                        }
                    />
                </div>
            }
        </>
    );
}
