import { useState, useEffect, useContext } from "react";
import { format, compareDesc } from "date-fns";
import { useFormValidation } from "hooks/form-validation";
import { APP_ID } from "config";
import { API_ROUTES } from "routes/api";
import { GLOBAL } from "constants/global";
import { ApiConsumer } from "api/ApiConsumer";
import { useDataLoaderHook } from "hooks/data-loader";
import * as Sentry from "@sentry/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import Select from "react-select";
import { AppLoaderContext } from "contexts/app-loader";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import DownloadAsPdf from "components/share/download-pdf";
import { useTextFormatHook } from "hooks/text-formatter";

export default function ViewAuditFindings({
    kpi,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { setLoading } = useContext(AppLoaderContext);

    const [auditFindings, setAuditFindings] = useState([]);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    let { normaliseTableData } = useTextFormatHook();

    const formFields = [];
    const validateFinding = (finding, pos) => {
        if (
            validFormDetails(
                formFields,
                formDetails[pos],
                setInvalidFormDetails,
                setFormError
            )
        ) {
            let formData = formDetails[pos];

            //If posting final auditor comments
            if (
                finding.management_response &&
                formDetails[pos]["final_auditor_comments"] === ""
            ) {
                setFormError((prevState) => ({
                    ...prevState,
                    [pos]: "Please enter final auditor comments.",
                }));
            } else if (formDetails[pos]["management_response"] === "") {
                setFormError((prevState) => ({
                    ...prevState,
                    [pos]: "Please enter management response.",
                }));
            } else {
                setLoading(true);
                //Update management response
                if (
                    finding.management_response_attachment === null &&
                    formDetails[pos]["management_response_attachment"] !== null
                ) {
                    formData = new FormData();
                    for (const key in formDetails[pos]) {
                        formData.append(key, formDetails[pos][key]);
                    }
                    formData.append(
                        "management_response_attachment",
                        formDetails[pos].management_response_attachment
                    );
                }

                ApiConsumer.put(
                    API_ROUTES.AUDIT.FINDINGS_DETAIL(finding.id),
                    formData
                )
                    .then((res) => {
                        setRowUpdated(true);

                        setFormError((prevState) => ({
                            ...prevState,
                            [pos]: "",
                        }));
                        loadFindings();
                    })
                    .catch((err) => {
                        Sentry.captureException(err);

                        setFormError((prevState) => ({
                            ...prevState,
                            [pos]: err.toString(),
                        }));
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const loadFindings = () => {
        //Get audit findings
        if (Object.keys(editableRow).length !== 0) {
            ApiConsumer.get(
                API_ROUTES.AUDIT.FINDINGS_BY_INDICATOR(editableRow.id)
            )
                .then((res) => {
                    res.data.sort((a, b) =>
                        compareDesc(new Date(a.created), new Date(b.created))
                    );

                    res.data.map((finding, i) => {
                        finding.formSubmitted = false;

                        setFormDetails((prevState) => ({
                            ...prevState,
                            [i]: {
                                ...prevState[i],
                                [`indicator`]: editableRow?.id,
                            },
                        }));

                        setFormDetails((prevState) => ({
                            ...prevState,
                            [i]: {
                                ...prevState[i],
                                [`kpa`]: kpi?.kpa?.id,
                            },
                        }));

                        setFormDetails((prevState) => ({
                            ...prevState,
                            [i]: {
                                ...prevState[i],
                                [`description`]: finding.description,
                            },
                        }));

                        setFormDetails((prevState) => ({
                            ...prevState,
                            [i]: {
                                ...prevState[i],
                                [`legislation_contravened`]:
                                    finding.legislation_contravened,
                            },
                        }));

                        setFormDetails((prevState) => ({
                            ...prevState,
                            [i]: {
                                ...prevState[i],
                                [`possible_impact`]: finding.possible_impact,
                            },
                        }));

                        setFormDetails((prevState) => ({
                            ...prevState,
                            [i]: {
                                ...prevState[i],
                                [`management_response`]:
                                    finding.management_response
                                        ? finding.management_response
                                        : "",
                            },
                        }));

                        if (finding.management_response) {
                            setFormDetails((prevState) => ({
                                ...prevState,
                                [i]: {
                                    ...prevState[i],
                                    [`final_auditor_comments`]:
                                        finding.final_auditor_comments
                                            ? finding.final_auditor_comments
                                            : "",
                                },
                            }));
                        } else {
                            setFormDetails((prevState) => ({
                                ...prevState,
                                [i]: {
                                    ...prevState[i],
                                    [`management_response_attachment`]:
                                        finding.management_response_attachment
                                            ? finding.management_response_attachment
                                            : "",
                                },
                            }));
                        }

                        return finding;
                    });
                    console.log(res.data);
                    setAuditFindings(res.data);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        }
    };

    useEffect(() => {
        loadFindings();

        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    const auditFindingsTableHeaders = [
        { key: "description", value: "Finding description", showOnTable: true, showOnPrint: true },
        {
            key: "legislation_contravened",
            value: "Legislation contravened",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "possible_impact",
            value: "Possible impact",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "management_response",
            value: "Management response",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "management_response_attachment",
            value: "Management response attachment",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "final_auditor_comments",
            value: "Final comments by Auditor",
            showOnTable: true,
            showOnPrint: true,
        }];

    return (
        <>
            <div className="mb-5 bg-red-100 p-4 rounded-md text-sm">
                <strong>{auditFindings.length} Audit Fundings</strong> under "
                {editableRow.indicator_name}"
            </div>

            {auditFindings.length ? <>
                        <div className="text-center mb-4"><DownloadAsPdf
                            tableTitle={`Indicator Audit Findings:`}
                            tableHeaders={auditFindingsTableHeaders}
                            tableData={normaliseTableData(auditFindingsTableHeaders, auditFindings)}
                            tableDataResult={editableRow.indicator_name}
                            printType={GLOBAL.PRINT.TABLE}
                            filename={`Indicator Audit Findings: ${editableRow.indicator_name}`}
                            downloadIconText={"Download Audit Findings"}
                        />
                        </div>
                        <div className="clear-both"></div>
                        </>
                      : null
                    }

            {auditFindings?.map((finding, i) => (
                <div key={i}>
                    <form
                        className="space-y-4 border-b pb-4 mb-4"
                        onSubmit={(event) => {
                            event.preventDefault();
                            validateFinding(finding, i);
                        }}
                        method="POST"
                        encType="multipart/form-data"
                    >
                        <Modal
                            modalHeaderTag={""}
                            modalHeader={`User Details: ${finding.created_by.first_name} ${finding.created_by.last_name}`}
                            modalContent={
                                <ModalUserDetails
                                    setOpen={setOpenUserDetails}
                                    userDetailsRow={userDetailsRow}
                                />
                            }
                            open={openUserDetails}
                            setOpen={setOpenUserDetails}
                        />
                        <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                            <span className="font-bold">Created:</span>{" "}
                            {format(new Date(finding.created), "dd MMM yyyy")}{" "}
                            by{" "}
                            <span
                                className="hover:underline cursor-pointer"
                                onClick={() => {
                                    setUserDetailsRow(finding.created_by);
                                    setOpenUserDetails(true);
                                }}
                            >
                                {finding.created_by.first_name +
                                    " " +
                                    finding.created_by.last_name}
                            </span>{" "}
                            <span className="font-bold">Last updated:</span>{" "}
                            {format(new Date(finding.updated), "dd MMM yyyy")}
                        </div>

                        <div className="space-y-2">
                            <div className="grid grid-cols-12">
                                <div className="col-span-4">
                                    <label
                                        className="text-sm font-semibold"
                                        htmlFor="description"
                                    >
                                        Finding description:
                                    </label>
                                </div>
                                <div className="col-span-8 text-sm">
                                    {finding.description
                                        ? finding.description
                                        : ""}
                                </div>
                            </div>

                            <div className="grid grid-cols-12">
                                <div className="col-span-4">
                                    <label
                                        className="text-sm font-semibold"
                                        htmlFor="legislation_contravened"
                                    >
                                        Legislation contravened:
                                    </label>
                                </div>
                                <div className="col-span-8 text-sm">
                                    {finding.legislation_contravened
                                        ? finding.legislation_contravened
                                        : ""}
                                </div>
                            </div>

                            <div className="grid grid-cols-12">
                                <div className="col-span-4">
                                    <label
                                        className="text-sm font-semibold"
                                        htmlFor="possible_impact"
                                    >
                                        Possible impact:
                                    </label>
                                </div>
                                <div className="col-span-8 text-sm">
                                    {finding.possible_impact
                                        ? finding.possible_impact
                                        : ""}
                                </div>
                            </div>

                            <div className="grid grid-cols-12 bg-green-100 p-4">
                                <div className="col-span-4">
                                    <label
                                        className="text-sm"
                                        htmlFor="management_response"
                                    >
                                        Management response:
                                    </label>
                                </div>
                                <div className="col-span-8 text-sm">
                                    {finding.management_response ? (
                                        <>
                                            {finding.management_response
                                                ? finding.management_response
                                                : ""}
                                        </>
                                    ) : (
                                        <textarea
                                            id="management_response"
                                            name="management_response"
                                            autoComplete="management_response"
                                            className={`form-field ${
                                                invalidFormDetails.management_response
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [i]: {
                                                        ...prevState[i],
                                                        [`management_response`]:
                                                            event.target.value,
                                                    },
                                                }));
                                            }}
                                        ></textarea>
                                    )}
                                </div>
                            </div>

                            <div className="grid grid-cols-12 bg-green-100 p-4">
                                <div className="col-span-4">
                                    <label
                                        className="text-sm"
                                        htmlFor="management_response_attachment"
                                    >
                                        Management response attachment
                                        (optional):
                                    </label>
                                </div>
                                <div className="col-span-8 text-sm pt-3">
                                    {finding.management_response ? (
                                        <>
                                            {finding.management_response_attachment ? (
                                                <>
                                                    <PaperClipIcon
                                                        className="h-5 w-5 float-left "
                                                        aria-hidden="true"
                                                    />
                                                    <a
                                                        href={
                                                            finding.management_response_attachment
                                                        }
                                                        target="_blank"
                                                        className="brand-link ml-1 truncate float-left"
                                                    >
                                                        View attachment
                                                    </a>
                                                </>
                                            ) : (
                                                "No attachment"
                                            )}
                                        </>
                                    ) : (
                                        <div className="flex space-x-2 mt-1">
                                            <input
                                                id="management_response_attachment"
                                                name="management_response_attachment"
                                                autoComplete="management_response_attachment"
                                                className={`form-field ${
                                                    invalidFormDetails.management_response_attachment
                                                        ? `border-red-600`
                                                        : `border-gray-300`
                                                }`}
                                                onChange={(event) => {
                                                    setFormDetails(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            [i]: {
                                                                ...prevState[i],
                                                                [`management_response_attachment`]:
                                                                    event.target
                                                                        .files[0],
                                                            },
                                                        })
                                                    );
                                                    setFormSubmitted(false);
                                                }}
                                                //value={formDetails.management_response_attachment}
                                                type="file"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {finding.management_response && (
                                <div className="grid grid-cols-12 bg-amber-100 p-4">
                                    <div className="col-span-4">
                                        <label
                                            className="text-sm"
                                            htmlFor="final_auditor_comments"
                                        >
                                            Final comments by Auditor:
                                        </label>
                                    </div>
                                    <div className="col-span-8 text-sm">
                                        {finding.final_auditor_comments ? (
                                            <>
                                                {finding.final_auditor_comments
                                                    ? finding.final_auditor_comments
                                                    : ""}
                                            </>
                                        ) : (
                                            <textarea
                                                id="final_auditor_comments"
                                                name="final_auditor_comments"
                                                autoComplete="final_auditor_comments"
                                                className={`form-field ${
                                                    invalidFormDetails.final_auditor_comments
                                                        ? `border-red-600`
                                                        : `border-gray-300`
                                                }`}
                                                onChange={(event) => {
                                                    setFormDetails(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            [i]: {
                                                                ...prevState[i],
                                                                [`final_auditor_comments`]:
                                                                    event.target
                                                                        .value,
                                                            },
                                                        })
                                                    );
                                                }}
                                            ></textarea>
                                        )}
                                    </div>
                                </div>
                            )}

                            {formError[i]?.length !== 0 && (
                                <div className="col-span-12 form-error text-center">
                                    {formError[i]}
                                </div>
                            )}

                            <div className="flex justify-center">
                                {(finding.management_response === null ||
                                    finding.management_response === "") && (
                                    <button
                                        type="submit"
                                        className="button mx-1 mt-4"
                                    >
                                        Add Management Response
                                    </button>
                                )}

                                {finding.management_response &&
                                    (finding.final_auditor_comments === null ||
                                        finding.final_auditor_comments ===
                                            "") && (
                                        <button
                                            type="submit"
                                            className="button mx-1 mt-4"
                                        >
                                            Add Final Comments by Auditor
                                        </button>
                                    )}
                            </div>
                        </div>
                    </form>
                </div>
            ))}

            <div className="flex justify-center">
                <button
                    type="button"
                    className="button-default mx-1 mt-4"
                    onClick={() => setOpen(false)}
                >
                    Close Popup
                </button>
            </div>
        </>
    );
}
