export const PERMISSIONS = {
    SECTIONS: {
        CAN_ADD_SECTION: "add_programme",
        CAN_EDIT_SECTION: "change_programme",
        CAN_CLOSE_SECTION: "close_programme",
        CAN_VIEW_SECTION: "view_programme",
        CAN_DELETE_SECTION: "delete_programme",
    },
    PROJECTS: {
        CAN_ADD_PROJECT: "add_project",
        CAN_EDIT_PROJECT: "change_project",
        CAN_CLOSE_PROJECT: "close_project",
        CAN_VIEW_PROJECT: "view_project",
        CAN_DELETE_PROJECT: "delete_project",
    },
    TASKS: {
        CAN_ADD_TASK: "add_task",
        CAN_EDIT_TASK: "change_task",
        CAN_VIEW_TASK: "view_task",
        CAN_DELETE_TASK: "delete_task",
    },
    PERMISSIONS: {
        CAN_ADD_PERMISSION: "add_permission",
        CAN_EDIT_PERMISSION: "change_permission",
        CAN_DELETE_PERMISSION: "delete_permission",
        CAN_VIEW_PERMISSION: "view_permission",
    },
};
