import { GLOBAL } from "constants/global";
import { API_ROUTES } from "routes/api";
import { getYear } from "date-fns";
import { ApiConsumer } from "api/ApiConsumer";

export const useTermsHook = () => {
    const getCurrentTermYears = () => {
        ApiConsumer.get(API_ROUTES.CONTENT.TERM).then((res) => {
            res.data.map((term) => {
                const termYears = GLOBAL.TERMS.map((termIndex) => {
                    return getYear(
                        new Date(term[`year` + termIndex + `_end_date`])
                    );
                });

                if (termYears.indexOf(getYear(new Date())) !== -1)
                    console.log(termYears);
            });
        });
        /* ApiConsumer.get(API_ROUTES.CONTENT.TERM).then((res) => {
            return res.data.filter((term) => {
                console.log(term);

                 let currentYear = GLOBAL.TERMS.map((year, i) => {
                   return getYear(new Date(term[`year` + year + `_start_date`]))
                })
                
                getYear(new Date()) ===currentYear) 
                //setTerm(res.data);
            });
        }); */
    };

    const getTermYearByIndex = (term, index) => {
        return getYear(new Date(term[`year` + index + `_end_date`]));
    };

    const getTermIndexByYear = (term, year) => {
        const termYears = GLOBAL.TERMS.map((termIndex) => {
            return getYear(new Date(term[`year` + termIndex + `_end_date`]));
        });
        return termYears.indexOf(parseInt(year)) + 1;
    };

    return {
        getCurrentTermYears,
        getTermYearByIndex,
        getTermIndexByYear,
    };
};
