import {Chart} from "react-google-charts";
import {useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";


export function Doughnut({url = '', name = '', title = ''}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const colours = ['#5fa83f', '#f72585', '#118ab2', '#ff9770', '#d35400']

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(url)
            .then(res => {
                let temp = [[name, 'Count']]
                res.data.map(r => {
                    temp.push([r.name, r.count])
                })
                setData(temp)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [url])

    const options = {
        title: "",
        pieHole: 0.5,
        is3D: false,
        legend: { position: 'bottom' },
        chartArea:{ width: '85%', height: '75%', top:'10' }
    };

    return <>
        <div className="min-w-0 flex-1">
            <div className="align-middle min-w-full overflow-x-auto shadow-sm overflow-hidden sm:rounded-lg">
                <h3 className='bg-white p-2 text-center'>{title}</h3>
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="250px"
                    data={data}
                    options={options}
                    className={'shadow-2xl rounded py-2 bg-white'}
                />
            </div>
        </div>
    </>
}
