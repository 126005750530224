import { useEffect, useState, Fragment, useContext } from "react";
import { PORTAL } from "routes/portal";
import { NavLink, useMatch, useLocation } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { compareDesc } from "date-fns";
import { useTextFormatHook } from "hooks/text-formatter";
import { AuthenticationContext } from "contexts/authentication";
import PageHeader from "components/page-header";
import Table from "components/table";
import AddEditSection from "components/forms/add-edit-section";
import Modal from "components/modal";
import Alert from "components/alert";
import Delete from "components/delete";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import { GLOBAL } from "constants/global";
import { Doughnut } from "components/charts/doughnut";
import { useAuthentication } from "hooks/authentication";
import { PERMISSIONS } from "constants/permissions";
import { AppLoader } from "components/loader/app-loader";
import { AppLoaderContext } from "contexts/app-loader";
import { usePermissionsHook } from "hooks/permissions";

export default function Sections() {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("search");
    let { setLoading } = useContext(AppLoaderContext);

    /* Programmes by KPA */
    const programmesByKpa = useMatch(PORTAL.SECTIONS_BY_KPA + "/:kpa_id");
    let kpaId = programmesByKpa?.params?.kpa_id;

    /* Programmes by type */
    const programmesByType = useMatch(PORTAL.SECTIONS_BY_TYPE + "/:type_id");
    let typeId = programmesByType?.params?.type_id;

    /* Programmes by department */
    const programmesByDepartment = useMatch(
        PORTAL.SECTIONS_BY_DEPARTMENT + "/:department_id"
    );
    let departmentId = programmesByDepartment?.params?.department_id;

    /* Programmes by user */
    const programmesByUser = useMatch(PORTAL.SECTIONS_BY_USER + "/:user_id");
    let userId = programmesByUser?.params?.user_id;

    /* Set programme API url */
    let programmeAPIURL = API_ROUTES.SECTION.SECTIONS;

    if (kpaId) programmeAPIURL = API_ROUTES.SECTION.SECTIONS_BY_KPA(kpaId);
    if (typeId) {
        programmeAPIURL = API_ROUTES.SECTION.SECTIONS_BY_TYPE(typeId);
    }
    if (departmentId)
        programmeAPIURL =
            API_ROUTES.SECTION.SECTIONS_BY_DEPARTMENT(departmentId);
    if (userId) programmeAPIURL = API_ROUTES.SECTION.SECTIONS_BY_USER(userId);

    let { hasPermission } = useAuthentication();
    let { normaliseTableData } = useTextFormatHook();
    const [kpa, setKpa] = useState({});
    const [programmes, setProgrammes] = useState([]);
    const [department, setDepartment] = useState({});
    const [type, setType] = useState({});
    const [user, setUser] = useState({});
    const [openAddEditSection, setOpenAddEditSection] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [filterString, setFilterString] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const pageHeaderButtons = [
        hasPermission(PERMISSIONS.SECTIONS.CAN_ADD_SECTION) && (
            <button
                type="button"
                onClick={() => setOpenAddEditSection(true)}
                className={`button`}
            >
                <span className="button-icon">
                    <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                Add Section/Group
            </button>
        ),
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    {/* <NavLink
                        to={`${PORTAL.PROJECTS_BY_SECTION}/${row.id}`}
                        className={`brand-link underline`}
                    > */}
                    {row.normalised.name}
                    {/* </NavLink> */}
                    <Delete
                        apiRoute={API_ROUTES.SECTION.SECTION_DETAIL}
                        rowData={row}
                        loadData={loadData}
                        titleValue={"Section/Group"}
                    />
                </>
            );
        });
        return tableData;
    };

    const loadData = () => {
        setLoading(true);
        ApiConsumer.get(programmeAPIURL)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.created), new Date(b.created))
                );
                //Group by "id" to avoid duplicates
                res.data = res.data.filter(
                    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                );

                if (
                    programmeAPIURL === API_ROUTES.SECTION.SECTIONS &&
                    searchTerm !== null &&
                    searchTerm !== ""
                ) {
                    let programmes = res.data;
                    res.data = programmes.filter((p) =>
                        p.name.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                }

                let normalisedData = normaliseTableData(
                    GLOBAL.SECTION_HEADERS,
                    res.data
                );

                setProgrammes(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setKpa({});
            setDepartment({});
            setProgrammes([]);
            setType({});
            setUser({});
            setOpenAddEditSection(false);
            setEditableRow({});
            setRowUpdated(false);
            setFilterString("");
            setFilteredData([]);
        };
    }, [programmeAPIURL, kpaId, typeId, departmentId, userId]);

    useEffect(() => {
        if (kpaId) {
            ApiConsumer.get(API_ROUTES.CONTENT.KPA_DETAIL(kpaId))
                .then((res) => {
                    setKpa(res.data);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }
    }, [kpaId, programmeAPIURL]);

    useEffect(() => {
        if (departmentId) {
            ApiConsumer.get(API_ROUTES.SECTION.DEPARTMENT_DETAIL(departmentId))
                .then((res) => {
                    setDepartment(res.data);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }
    }, [departmentId, programmeAPIURL]);

    useEffect(() => {
        if (typeId) {
            ApiConsumer.get(API_ROUTES.SECTION.TYPE_DETAIL(typeId))
                .then((res) => {
                    setType(res.data);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        }
    }, [typeId, programmeAPIURL]);

    useEffect(() => {
        if (userId) {
            ApiConsumer.get(API_ROUTES.USERS.USER_DETAIL(userId))
                .then((res) => {
                    setUser(res.data);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }
    }, [userId, programmeAPIURL]);

    const onCloseAddEditSection = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditSection(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditSection(false)
            : setOpenAddEditSection(true);
    }, [editableRow]);

    return (
        <>
            <Modal
                modalHeaderTag={
                    kpa?.name !== undefined
                        ? `KPA: ` + kpa.name
                        : editableRow?.kpa?.name !== undefined
                        ? `KPA: ` + editableRow.kpa.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Section/Group`
                        : `Edit Section/Group: ${editableRow.name}`
                }
                modalContent={
                    <AddEditSection
                        type={
                            editableRow?.type !== undefined
                                ? editableRow.type
                                : type
                        }
                        kpa={
                            editableRow?.kpa !== undefined
                                ? editableRow.kpa
                                : kpa
                        }
                        setOpen={onCloseAddEditSection}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditSection}
                setOpen={onCloseAddEditSection}
            />

            <PageHeader
                pageHeaderName={`${
                    kpa?.name !== undefined
                        ? kpa.name + " "
                        : type?.name !== undefined
                        ? type?.name + " "
                        : department?.name !== undefined
                        ? department.name + " "
                        : ""
                }${
                    user?.first_name !== undefined &&
                    user?.last_name !== undefined
                        ? user.first_name + " " + user.last_name + " "
                        : ""
                }Sections/Groups (${programmes?.length}) ${
                    searchTerm !== null && searchTerm !== ""
                        ? ' Search results for "' + searchTerm + '"'
                        : ""
                }`}
                pageHeaderButtons={pageHeaderButtons}
            />
            {/* kpaId !== undefined && (
                <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <div className="flex space-x-3">
                        <Doughnut
                            url={API_ROUTES.SECTION.GRAPHS.KPA.TYPE(kpaId)}
                            title={"Programmes by Type"}
                        />
                        <Doughnut
                            url={API_ROUTES.SECTION.GRAPHS.KPA.SECTION_BY_PROGRESS(
                                kpaId
                            )}
                            title={"Programmes by progress"}
                        />
                    </div>
                </div>
            ) */}

            {hasPermission(PERMISSIONS.SECTIONS.CAN_VIEW_SECTION) ? (
                <div className="relative">
                    <AppLoader
                        pageContent={
                            programmes.length ? (
                                <Table
                                    tableTitle={`${
                                        kpa?.name !== undefined
                                            ? kpa.name
                                            : type?.name !== undefined
                                            ? type?.name
                                            : department?.name !== undefined
                                            ? department.name
                                            : ""
                                    }${
                                        user?.first_name !== undefined &&
                                        user?.last_name !== undefined
                                            ? user.first_name +
                                              " " +
                                              user.last_name +
                                              " "
                                            : ""
                                    }Programmes`}
                                    tableHeaders={GLOBAL.SECTION_HEADERS}
                                    tableData={programmes}
                                    editable={
                                        hasPermission(
                                            PERMISSIONS.SECTIONS
                                                .CAN_EDIT_SECTION
                                        )
                                            ? true
                                            : false
                                    }
                                    setEditableRow={setEditableRow}
                                />
                            ) : (
                                <div className="mx-full px-8 my-4 text-center">
                                    <Alert
                                        type={`danger`}
                                        message={`There are no programmes added under "${
                                            kpa?.name !== undefined
                                                ? kpa.name
                                                : type?.name !== undefined
                                                ? type?.name
                                                : department?.name !== undefined
                                                ? department.name
                                                : ""
                                        }${
                                            user?.first_name !== undefined &&
                                            user?.last_name !== undefined
                                                ? user.first_name +
                                                  " " +
                                                  user.last_name
                                                : ""
                                        }${
                                            searchTerm !== null &&
                                            searchTerm !== ""
                                                ? searchTerm
                                                : ""
                                        } Programmes"`}
                                    />
                                </div>
                            )
                        }
                    />
                </div>
            ) : (
                <div className="mx-full px-8 my-4 text-center">
                    <Alert
                        type={`danger`}
                        message={`You do not have permission to view programmes. Please contact the system administrator.`}
                    />
                </div>
            )}
        </>
    );
}
