import { useContext, useState, useEffect } from 'react'
import { Navigate, useLocation, Outlet } from 'react-router-dom'
import { useAuthentication } from 'hooks/authentication'
import { AuthenticationContext } from 'contexts/authentication'
import { PORTAL } from 'routes/portal'
import Navigation from 'components/navigation'
import Header from 'components/header'

export default function PrivateRoute({ children }) {
  let { isAuthenticated } = useAuthentication()
  let { currentUser } = useContext(AuthenticationContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    return () => {
      setSidebarOpen(false)
    }
  }, [])

  return (
    <>
      {isAuthenticated() && Object.keys(currentUser).length !== 0 ? (
        children ? (
          children
        ) : (
          <div className="min-h-full bg-gray-100">
            <Navigation
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
            <div className="lg:pl-64 flex flex-col flex-1">
              <Header />
              <main className="flex-1 pb-8">
                <Outlet />
              </main>
            </div>
          </div>
        )
      ) : (
        <Navigate
          to={{
            pathname: PORTAL.LOGIN,
            state: { referrer: location.pathname },
          }}
          from={location.pathname}
        />
      )}
    </>
  )
}
