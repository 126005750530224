import {useEffect, useState, useContext} from "react";
import {PORTAL} from "routes/portal";
import {NavLink, useMatch, useLocation} from "react-router-dom";
import {ChevronLeftIcon, ChevronRightIcon, PencilIcon} from "@heroicons/react/24/outline";
import {useTextFormatHook} from "hooks/text-formatter";
import {format} from "date-fns";
import PageHeader from "components/page-header";
import AddEditTask from "components/forms/add-edit-project";
/* import TaskActivity from "components/task-activity"; */
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import Alert from "components/alert";
import ViewUserDetails from "components/view-user-details";
import Attachments from "components/attachments";
import AddEditProject from "components/forms/add-edit-project";
import * as Sentry from "@sentry/react";
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import Status from "components/progress-slider/status";
import Progress from "components/progress-slider/progress";
import Bar from "components/progress-slider/bar";
import BarQuarters from "components/progress-slider/bar-quarters";
import DownloadAsPdf from "components/share/download-pdf";
import {GLOBAL} from "constants/global";
import {useAuthentication} from "hooks/authentication";
import {PERMISSIONS} from "constants/permissions";
import {AppLoader} from "components/loader/app-loader";
import {AppLoaderContext} from "contexts/app-loader";
import SubTasks from "components/sub-tasks";
import {useDataLoaderHook} from "hooks/data-loader";
import {useTermsHook} from "hooks/terms";
import AddEditTIDInformation from "components/forms/add-edit-tid-information";
import AddEditComments from "components/forms/add-edit-comments";
import AddEditPOE from "components/forms/add-edit-poe";
import AddEditChallenges from "components/forms/add-edit-challenges";
import AddEditRisk from "../components/forms/add-edit-risk";

export default function Project() {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("search");
    const projectId = useMatch(PORTAL.PROJECT + "/:project_id").params.project_id;

    let {setLoading} = useContext(AppLoaderContext);
    let {normaliseTableData} = useTextFormatHook();
    let {hasPermission} = useAuthentication();
    let {loadDropdown} = useDataLoaderHook();
    let {getTermIndexByYear} = useTermsHook();

    const [maxValues, setMaxValues] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [project, setProject] = useState({});

    const [yearIndex, setYearIndex] = useState(0);

    const [progressSubmitted, setProgressSubmitted] = useState(false);
    const [progressDetails, setProgressDetails] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [openAddEditProject, setOpenAddEditProject] = useState(false);

    const [editableRow, setEditableRow] = useState({});
    const [editableProject, setEditableProject] = useState({});

    const [rowUpdated, setRowUpdated] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [projectActivities, setProjectActivities] = useState([]);

    const [openAddEditChallenges, setOpenAddEditChallenges] = useState(false);
    const [editableChallenges, setEditableChallenges] = useState({});
    const [openAddEditTIDInformation, setOpenAddEditTIDInformation] =
        useState(false);
    const [editableTIDInformation, setEditableTIDInformation] = useState({});
    const [openAddEditComments, setOpenAddEditComments] = useState(false);
    const [editableComments, setEditableComments] = useState({});
    const [openAddEditPOE, setOpenAddEditPOE] = useState(false);
    const [openAddEditRisk, setOpenAddEditRisk] = useState(false);
    const [editablePOE, setEditablePOE] = useState({});

    const numberFormatter = new Intl.NumberFormat("en-US");
    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2,
    });

    const pageHeaderButtons = [
        <NavLink
            to={`${PORTAL.PROJECTS}/${project?.year}/${project?.kpa?.id}/${project?.indicator?.id}`}
            className={`button-default`}
        >
            <span className="button-icon">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
            </span>
            Back to Project List
        </NavLink>,
    ];
    ;

    const pageHeaderIcons = [
        !project.closed &&
        hasPermission(PERMISSIONS.PROJECTS.CAN_EDIT_PROJECT) && (
            <button
                type="button"
                className="icon-transparent bg-gray-100 ml-3"
            >
                <PencilIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                    onClick={() => {
                        setEditableProject(project);
                        setOpenAddEditProject(true);
                    }}
                />
            </button>
        ),
        <DownloadAsPdf
            tableTitle={`Project:`}
            tableHeaders={GLOBAL.PROJECT_HEADERS}
            tableData={[project]}
            tableDataResult={project?.name}
            printType={GLOBAL.PRINT.PAGE}
            filename={`Project: ${project?.name}`}
        />,
    ];


    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.PROJECT}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const loadData = () => {
        setLoaded(false);

        loadDropdown(API_ROUTES.PROJECT.ACTIVITY, setProjectActivities);

        ApiConsumer.get(API_ROUTES.PROJECT.PROJECT_DETAIL(projectId))
            .then((res) => {
                let normalisedData = normaliseTableData(GLOBAL.PROJECT_HEADERS, [
                    res.data,
                ]);
                setProject(addLinksToTableData(normalisedData)[0]);

                setYearIndex(
                    getTermIndexByYear(
                        res.data.kpa.term,
                        res.data.year
                    )
                );

            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    const updateProjectProgress = (project) => {
        setLoading(true);

        let projectObj = {
            ...project,
            ...progressDetails,
            project_id: project.id,
            kpa: project.kpa.id,
            indicator: project.indicator.id,
            deliverables: project.deliverables.map((deliverable) => {
                return deliverable.id;
            }),
            departments: project.departments.map((department) => {
                return department.id;
            }),
            partners: project.partners.map((partner) => {
                return partner.id;
            }),
            programme: project.programme.id,
            reporting_managers: project.reporting_managers.map((reporting_manager) => {
                return reporting_manager.id;
            }),
            /* progress: calculateProgress(), */
            quarter1:
                progressDetails.quarter1 !== null
                    ? parseInt(progressDetails.quarter1)
                    : null,
            quarter2:
                progressDetails.quarter2 !== null
                    ? parseInt(progressDetails.quarter2)
                    : null,
            quarter3:
                progressDetails.quarter3 !== null
                    ? parseInt(progressDetails.quarter3)
                    : null,
            quarter4:
                progressDetails.quarter4 !== null
                    ? parseInt(progressDetails.quarter4)
                    : null,
            quarter1_text:
                progressDetails.quarter1_text !== null
                    ? progressDetails.quarter1_text
                    : null,
            quarter2_text:
                progressDetails.quarter2_text !== null
                    ? progressDetails.quarter2_text
                    : null,
            quarter3_text:
                progressDetails.quarter3_text !== null
                    ? progressDetails.quarter3_text
                    : null,
            quarter4_text:
                progressDetails.quarter4_text !== null
                    ? progressDetails.quarter4_text
                    : null,
        };

        delete projectObj.id;
        delete projectObj.created;
        delete projectObj.created_by;
        delete projectObj.updated;
        delete projectObj.normalised;

        ApiConsumer.put(API_ROUTES.PROJECT.PROJECT_DETAIL(project.id), projectObj)
            .then((res) => {
                loadData();
                setProgressSubmitted(true);
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        /* Defaults */
        if (Object.keys(project).length !== 0) {
            console.log("PROJECT");
            console.log(project);
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter1`]: project?.quarter1 !== null ? project?.quarter1 : null,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter2`]: project?.quarter2 !== null ? project?.quarter2 : null,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter3`]: project?.quarter3 !== null ? project?.quarter3 : null,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter4`]: project?.quarter4 !== null ? project?.quarter4 : null,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter1_text`]:
                    project?.quarter1_text !== null ? project?.quarter1_text : null,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter2_text`]:
                    project?.quarter2_text !== null ? project?.quarter2_text : null,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter3_text`]:
                    project?.quarter3_text !== null ? project?.quarter3_text : null,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter4_text`]:
                    project?.quarter4_text !== null ? project?.quarter4_text : null,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`progress`]: project?.progress ? project?.progress : 0,
            }));

            setProgressDetails((prevState) => ({
                ...prevState,
                [`score_type`]:
                    project.indicator.indicator_umo.name.toUpperCase(),
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`baseline`]: project.indicator.baseline === null ? 0 : project.indicator.baseline,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`target`]: project.indicator.indicator_year5,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter1_max`]: project.indicator.q1,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter2_max`]: project.indicator.q2,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter3_max`]: project.indicator.q3,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`quarter4_max`]: project.indicator.q4,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`q1_total`]: project.indicator.q1_total,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`q2_total`]: project.indicator.q2_total,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`q3_total`]: project.indicator.q3_total,
            }));
            setProgressDetails((prevState) => ({
                ...prevState,
                [`q4_total`]: project.indicator.q4_total,
            }));
        }

    }, [project]);

    useEffect(() => {

        if (
            yearIndex &&
            project?.indicator[`indicator_year` + yearIndex] !== undefined &&
            project?.indicator[`indicator_year` + yearIndex] %
            1 ===
            0
        ) {
            let maxIteration =
                project?.indicator[`indicator_year` + yearIndex] /
                1;

            let maxValueByTarget = [];
            for (let i = 0; i <= maxIteration; i++) {
                maxValueByTarget.push(i * 1);
            }

            setMaxValues(maxValueByTarget);
        }
    }, [project, project, yearIndex]);

    useEffect(() => {
        loadData();
        return () => {
            setSidebarOpen(false);
            setProject({});

            setLoaded(false);
            setOpenAddEditProject(false);

            setEditableRow({});
            setEditableProject({});

            setRowUpdated(false);
        };
    }, [projectId]);

    const onCloseAddEditProject = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableProject({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditProject(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableProject).length === 0
            ? setOpenAddEditProject(false)
            : setOpenAddEditProject(true);
    }, [editableProject]);

    const calculateProgress = function () {
        let annualProgress =
            parseInt(progressDetails.quarter1 ? progressDetails.quarter1 : 0) +
            parseInt(progressDetails.quarter2 ? progressDetails.quarter2 : 0) +
            parseInt(progressDetails.quarter3 ? progressDetails.quarter3 : 0) +
            parseInt(progressDetails.quarter4 ? progressDetails.quarter4 : 0);
        return annualProgress;
    };

    const onCloseAddEditTIDInformation = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableTIDInformation({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditTIDInformation(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableTIDInformation).length === 0
            ? setOpenAddEditTIDInformation(false)
            : setOpenAddEditTIDInformation(true);
    }, [editableTIDInformation]);

    const onCloseAddEditComments = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableComments({});
        }
        setOpenAddEditComments(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableComments).length === 0
            ? setOpenAddEditComments(false)
            : setOpenAddEditComments(true);
    }, [editableComments]);

    const onCloseAddEditPOE = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditablePOE({});
        }
        setOpenAddEditPOE(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editablePOE).length === 0
            ? setOpenAddEditPOE(false)
            : setOpenAddEditPOE(true);
    }, [editablePOE]);

    const onCloseAddEditChallenges = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableChallenges({});
        }
        setOpenAddEditChallenges(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableChallenges).length === 0
            ? setOpenAddEditChallenges(false)
            : setOpenAddEditChallenges(true);
    }, [editableChallenges]);

    return (
        <>
            <Modal
                modalHeaderTag={
                    project?.kpa?.name !== undefined
                        ? `SECTION: ` + project.name
                        : editableProject?.project?.name !== undefined
                            ? `SECTION: ` + editableProject.programme.name
                            : ""
                }
                modalHeader={
                    Object.keys(editableProject).length === 0
                        ? `Add New Project`
                        : `Edit Project: ${editableProject.name}`
                }
                modalContent={
                    <AddEditProject
                        year={
                            editableRow?.year !== undefined
                                ? editableRow.year
                                : project?.year
                        }
                        kpaId={
                            editableProject?.kpa?.id !== undefined
                                ? editableProject.kpa.id
                                : project?.kpa?.id
                        }
                        kpi={project?.indicator}
                        setOpen={onCloseAddEditProject}
                        editableRow={editableProject}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditProject}
                setOpen={onCloseAddEditProject}
            />


            <PageHeader
                pageHeaderName={`${
                    project?.name !== undefined
                        ? `Project: ` + project.name
                        : ""
                }`}
                pageHeaderButtons={pageHeaderButtons}
                pageHeaderIcons={pageHeaderIcons}
            />


            {hasPermission(PERMISSIONS.PROJECTS.CAN_VIEW_PROJECT) ? (
                loaded && (
                    <>
                        <Modal
                            modalHeaderTag={`PROJECT: ` + project?.name}
                            modalHeader={`Project Challenges`}
                            modalContent={
                                <AddEditChallenges
                                    type="project"
                                    obj={project}
                                    route={API_ROUTES.CHALLENGES.PROJECTS}
                                />
                            }
                            open={openAddEditChallenges}
                            setOpen={onCloseAddEditChallenges}
                        />
                        <Modal
                            modalHeaderTag={`PROJECT: ` + project?.name}
                            modalHeader={`Project Comments`}
                            modalContent={
                                <AddEditComments
                                    type="project"
                                    obj={project}
                                    route={API_ROUTES.COMMENTS.PROJECTS}
                                />
                            }
                            open={openAddEditComments}
                            setOpen={onCloseAddEditComments}
                        />

                        <Modal
                            modalHeaderTag={`PROJECT: ` + project?.name}
                            modalHeader={`Project POE`}
                            modalContent={
                                <AddEditPOE
                                    type="project"
                                    obj={project}
                                    route={API_ROUTES.ATTACHMENTS.PROJECTS}
                                />
                            }
                            open={openAddEditPOE}
                            setOpen={onCloseAddEditPOE}
                        />

                        <Modal
                            modalHeaderTag={`PROJECT: ` + project?.name}
                            modalHeader={`Project Risk`}
                            modalContent={
                                <AddEditRisk
                                    type="project"
                                    obj={project}
                                    route={API_ROUTES.ATTACHMENTS.PROJECTS}
                                />
                            }
                            open={openAddEditRisk}
                            setOpen={onCloseAddEditPOE}
                        />

                        <Modal
                            modalHeaderTag={`PROJECT: ` + project?.name}
                            modalHeader={`Project TID Information`}
                            modalContent={
                                <AddEditTIDInformation
                                    type="project"
                                    obj={project}
                                    route={API_ROUTES.TID.PROJECTS}
                                />
                            }
                            open={openAddEditTIDInformation}
                            setOpen={onCloseAddEditTIDInformation}
                        />
                        <Modal
                            modalHeaderTag={""}
                            modalHeader={`User Details: ${project.created_by.first_name} ${project.created_by.last_name}`}
                            modalContent={
                                <ModalUserDetails
                                    setOpen={setOpenUserDetails}
                                    userDetailsRow={userDetailsRow}
                                />
                            }
                            open={openUserDetails}
                            setOpen={setOpenUserDetails}
                        />
                        <div className="mt-8 grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                                <section>
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="p-5 pb-0">
                                            <div className="flex items-center">
                                                <h2
                                                    id="applicant-information-title"
                                                    className="flex-1 text-lg font-medium text-gray-900"
                                                >
                                                    <strong>KPA</strong>
                                                </h2>
                                            </div>
                                            <p className="mt-1 max-w-2xl  text-gray-500">
                                                {project.kpa.name}
                                            </p>
                                        </div>
                                        <div className="p-5 pb-0">
                                            <div className="flex items-center">
                                                <h2
                                                    id="applicant-information-title"
                                                    className="flex-1 text-lg font-medium text-gray-900"
                                                >
                                                    <strong>KPI</strong>
                                                </h2>
                                            </div>
                                            <p className="mt-1 max-w-2xl  text-gray-500">
                                                {project.indicator.indicator_name}
                                            </p>
                                        </div>

                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <h2
                                                    id="applicant-information-title"
                                                    className="flex-1 text-lg font-medium text-gray-900"
                                                >
                                                    <strong>Project Overview</strong>
                                                </h2>
                                            </div>
                                            <p className="mt-1 max-w-2xl  text-gray-500">
                                                {project.overview}
                                            </p>
                                        </div>
                                        <div className="border-t border-gray-200 p-5">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">

                                                <div className="sm:col-span-2">
                                                    <dt className=" font-base ">
                                                        Project Date &amp;
                                                        Duration:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {
                                                            project.normalised
                                                                .start_date
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            project.normalised
                                                                .end_date
                                                        }{" "}
                                                        (
                                                        {
                                                            project.normalised
                                                                .duration
                                                        }
                                                        )
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Section/Group:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project.normalised.programme}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Ward number:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project.wardnum}
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        GPS Coordinates (Long):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project.longitude}
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        GPS Coordinates (Lat):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project.latitude}
                                                    </dd>
                                                </div>


                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Corporate Plan Ref No:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {project.idp_ref}
                                                    </dd>
                                                </div>


                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Draft Budget (R):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {currencyFormatter
                                                            .format(
                                                                project.budget
                                                            )
                                                            .replace(
                                                                /[ZA\s]/g,
                                                                ""
                                                            )}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        2nd Draft Budget (R):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {currencyFormatter
                                                            .format(
                                                                project.second_budget
                                                            )
                                                            .replace(
                                                                /[ZA\s]/g,
                                                                ""
                                                            )}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Final Budget (R):
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {currencyFormatter
                                                            .format(
                                                                project.actual_budget
                                                            )
                                                            .replace(
                                                                /[ZA\s]/g,
                                                                ""
                                                            )}
                                                    </dd>
                                                </div>


                                                <div className="sm:col-span-1">
                                                    <dt className=" font-base ">
                                                        Score Type:
                                                    </dt>
                                                    <dd className="mt-1  text-gray-500">
                                                        {
                                                            project.indicator
                                                                .indicator_umo
                                                                .name
                                                        }
                                                    </dd>
                                                </div>
                                                {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                `PERCENTAGE` ||
                                                project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                `NUMBER` ? <>
                                                    <div className="sm:col-span-1">
                                                        <dt className=" font-base ">
                                                            Baseline (Previous
                                                            Year):
                                                        </dt>
                                                        <dd className="mt-1  text-gray-500">
                                                            {
                                                                project.indicator
                                                                    .baseline
                                                            }
                                                            {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                            `PERCENTAGE`
                                                                ? `%`
                                                                : ""}
                                                        </dd>
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <dt className=" font-base ">
                                                            Annual Target (Current
                                                            Year):
                                                        </dt>
                                                        <dd className="mt-1  text-gray-500">
                                                            {
                                                                project?.indicator[
                                                                `indicator_year` +
                                                                yearIndex
                                                                    ]
                                                            }
                                                            {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                            `PERCENTAGE`
                                                                ? `%`
                                                                : ""}
                                                        </dd>
                                                    </div>
                                                </> : null}

                                            </dl>
                                        </div>

                                        {project?.partners?.length ? (
                                            <div className="border-t border-gray-200 p-5">
                                                <p className="pt-1">
                                                    <strong>Funded By:</strong>{" "}
                                                    {project?.partners?.map(
                                                        (partner, i) => (
                                                            <span
                                                                key={i}
                                                                className="mr-1 rounded-md bg-gray-100 py-1 px-2 text-sm"
                                                            >
                                                                {partner.name}
                                                            </span>
                                                        )
                                                    )}
                                                </p>
                                            </div>
                                        ) : null}

                                        <div className="border-t border-gray-200 p-5">
                                            <ViewUserDetails row={project}/>
                                            <p className="pt-1">
                                                <strong>
                                                    Department / Division:
                                                </strong>{" "}
                                                {project?.departments?.map(
                                                    (department, i) => (
                                                        <span
                                                            key={i}
                                                            className="mr-1 rounded-md bg-gray-100 py-1 px-2 text-sm"
                                                        >
                                                            {department.name}
                                                        </span>
                                                    )
                                                )}
                                            </p>

                                        </div>

                                        <div
                                            className="border-t border-gray-200 p-5 rounded-bl-lg rounded-br-lg bg-gray-100 text-sm">
                                            <span className="font-bold">
                                                Created:
                                            </span>{" "}
                                            {format(
                                                new Date(project.created),
                                                "dd MMM yyyy"
                                            )}{" "}
                                            by{" "}
                                            <span
                                                className="hover:underline cursor-pointer"
                                                onClick={() => {
                                                    setUserDetailsRow(
                                                        project.created_by
                                                    );
                                                    setOpenUserDetails(true);
                                                }}
                                            >
                                                {project.created_by.first_name +
                                                    " " +
                                                    project.created_by.last_name}
                                            </span>{" "}
                                            <span className="font-bold">
                                                Last updated:
                                            </span>{" "}
                                            {project.normalised.updated}
                                        </div>
                                    </div>
                                </section>

                                <section
                                    aria-labelledby="notes-title"
                                    className="relative"
                                >
                                    <AppLoader
                                        pageContent={
                                            <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                                                <div className="divide-y divide-gray-200">
                                                    <div className="px-4 py-5 sm:px-6">
                                                        {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                        `PERCENTAGE` ||
                                                        project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                        `NUMBER` ? (
                                                            <div className="sm:col-span-1">
                                                                <h2 className="text-lg font-medium text-gray-900 text-center">
                                                                    <strong>
                                                                        Annual
                                                                        Progress
                                                                    </strong>
                                                                </h2>
                                                                <div
                                                                    className="mt-1 text-center font-semibold text-3xl">
                                                                    {numberFormatter.format(
                                                                        project.progress
                                                                    )}
                                                                    %
                                                                </div>
                                                                <div className="mt-1  text-gray-900">
                                                                    <div className="pt-2">
                                                                        <div
                                                                            className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                                            <Bar
                                                                                progressValue={
                                                                                    project.progress
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="pt-1 text-gray-900 text-sm">
                                                                        <div className="flex justify-between mb-1">
                                                                            <Status
                                                                                progressValue={
                                                                                    project.progress
                                                                                }
                                                                            />
                                                                            <Progress
                                                                                progressValue={
                                                                                    project.progress
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        {progressSubmitted && (
                                                            <div className="col-span-12 form-success text-center">
                                                                Quarterly
                                                                targets have
                                                                been
                                                                successfully
                                                                updated.
                                                            </div>
                                                        )}
                                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-1">
                                                            <div className="sm:col-span-1 py-3 text-center">
                                                                {!project.closed &&
                                                                    hasPermission(
                                                                        PERMISSIONS
                                                                            .PROJECTS
                                                                            .CAN_EDIT_PROJECT
                                                                    ) && (
                                                                        <>
                                                                            <button
                                                                                type="button"
                                                                                className="button inline"
                                                                                onClick={() =>
                                                                                    updateProjectProgress(
                                                                                        project
                                                                                    )
                                                                                }
                                                                            >
                                                                                Update
                                                                                Progress
                                                                            </button>
                                                                        </>
                                                                    )}
                                                            </div>
                                                        </dl>
                                                    </div>
                                                    <div className="px-4 py-5 sm:px-6">
                                                        <h2
                                                            id="notes-title"
                                                            className="text-lg font-medium text-gray-900"
                                                        >
                                                            <strong>
                                                                Quarterly
                                                                Targets
                                                            </strong>
                                                            <span className="ml-1 header-tag-green">
                                                                SCORE TYPE:{" "}
                                                                {project?.indicator.indicator_umo?.name.toUpperCase()}
                                                                {
                                                                    project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                    `PERCENTAGE` ||
                                                                    project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                    `NUMBER` ? <>
                                                                        {" "}
                                                                        | ANNUAL
                                                                        TARGET:{" "}
                                                                        {
                                                                            project
                                                                                ?.indicator[
                                                                            `indicator_year` +
                                                                            yearIndex
                                                                                ]
                                                                        }
                                                                        {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                        `PERCENTAGE`
                                                                            ? `%`
                                                                            : ""}
                                                                    </> : null
                                                                }
                                                            </span>
                                                        </h2>
                                                    </div>

                                                    <div className="px-4 py-6 sm:px-6">
                                                        {GLOBAL.QUARTERS.map(
                                                            (quarter, i) => (
                                                                <div
                                                                    key={i}
                                                                    className="sm:col-span-1 bg-white shadow sm:rounded-lg sm:overflow-hidden border border-gray-200 mb-4"
                                                                >
                                                                    <div
                                                                        className="bg-gray-100 p-2 border-b border-gray-200">
                                                                        <div className="flex">
                                                                            <div className="mr-2 mt-1.5 font-bold">
                                                                                Quarter{" "}
                                                                                {
                                                                                    quarter
                                                                                }
                                                                            </div>

                                                                            <div className="mr-2 mt-1.5 flex-grow">
                                                                                {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                                `PERCENTAGE` ||
                                                                                project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                                `NUMBER` ?
                                                                                    <>
                                                                                        (Target:{" "}
                                                                                        {
                                                                                            project
                                                                                                .indicator[
                                                                                            `q` +
                                                                                            quarter
                                                                                                ]
                                                                                        }
                                                                                        {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                                        `PERCENTAGE`
                                                                                            ? `%`
                                                                                            : ""}

                                                                                        )
                                                                                    </> : null
                                                                                }
                                                                            </div>

                                                                            {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                            `PERCENTAGE` ||
                                                                            project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                            `NUMBER` ? (
                                                                                <select
                                                                                    id={
                                                                                        `quarter` +
                                                                                        quarter
                                                                                    }
                                                                                    name={
                                                                                        `quarter` +
                                                                                        quarter
                                                                                    }
                                                                                    autoComplete={
                                                                                        `quarter` +
                                                                                        quarter
                                                                                    }
                                                                                    className={`m-0 p-0 w-auto`}
                                                                                    onChange={(
                                                                                        event
                                                                                    ) => {
                                                                                        setProgressDetails(
                                                                                            (
                                                                                                prevState
                                                                                            ) => ({
                                                                                                ...prevState,
                                                                                                [`quarter` +
                                                                                                quarter]:
                                                                                                event
                                                                                                    .target
                                                                                                    .value,
                                                                                            })
                                                                                        );
                                                                                        setProgressSubmitted(
                                                                                            ""
                                                                                        );
                                                                                    }}
                                                                                    value={
                                                                                        progressDetails[
                                                                                        `quarter` +
                                                                                        quarter
                                                                                            ] !==
                                                                                        null
                                                                                            ? progressDetails[
                                                                                            `quarter` +
                                                                                            quarter
                                                                                                ]
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    <option
                                                                                        value={
                                                                                            ""
                                                                                        }
                                                                                    >
                                                                                        N/A
                                                                                    </option>
                                                                                    {maxValues.map(
                                                                                        (
                                                                                            value,
                                                                                            i
                                                                                        ) => (
                                                                                            <option
                                                                                                key={
                                                                                                    i
                                                                                                }
                                                                                                value={
                                                                                                    value
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    value
                                                                                                }
                                                                                                {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                                                `PERCENTAGE`
                                                                                                    ? `%`
                                                                                                    : ""}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            ) : (
                                                                                <select
                                                                                    id={
                                                                                        `quarter` +
                                                                                        quarter
                                                                                    }
                                                                                    name={
                                                                                        `quarter` +
                                                                                        quarter
                                                                                    }
                                                                                    autoComplete={
                                                                                        `quarter` +
                                                                                        quarter
                                                                                    }
                                                                                    className={`m-0 p-0 w-auto`}
                                                                                    onChange={(
                                                                                        event
                                                                                    ) => {
                                                                                        setProgressDetails(
                                                                                            (
                                                                                                prevState
                                                                                            ) => ({
                                                                                                ...prevState,
                                                                                                [`quarter` +
                                                                                                quarter +
                                                                                                `_text`]:
                                                                                                event
                                                                                                    .target
                                                                                                    .value,
                                                                                            })
                                                                                        );
                                                                                        setProgressSubmitted(
                                                                                            ""
                                                                                        );
                                                                                    }}
                                                                                    value={
                                                                                        progressDetails[
                                                                                        `quarter` +
                                                                                        quarter +
                                                                                        `_text`
                                                                                            ] !==
                                                                                        null
                                                                                            ? progressDetails[
                                                                                            `quarter` +
                                                                                            quarter +
                                                                                            `_text`
                                                                                                ]
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    <option
                                                                                        value={
                                                                                            ""
                                                                                        }
                                                                                    >
                                                                                        N/A
                                                                                    </option>
                                                                                    {projectActivities.map(
                                                                                        (
                                                                                            projectType
                                                                                        ) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={
                                                                                                        projectType.id
                                                                                                    }
                                                                                                    value={
                                                                                                        projectType.id
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        projectType.name
                                                                                                    }
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </select>
                                                                            )}
                                                                        </div>
                                                                        {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                        `PERCENTAGE` ||
                                                                        project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                                        `NUMBER` ? (
                                                                            <div className="mt-1">
                                                                                <BarQuarters
                                                                                    progressValue={
                                                                                        progressDetails[
                                                                                        `quarter` +
                                                                                        quarter
                                                                                            ]
                                                                                            ? progressDetails[
                                                                                            `quarter` +
                                                                                            quarter
                                                                                                ]
                                                                                            : 0
                                                                                    }
                                                                                    progressMax={
                                                                                        project[
                                                                                        `quarter` +
                                                                                        quarter +
                                                                                        `_max`
                                                                                            ]
                                                                                    }
                                                                                    progressTarget={parseInt(
                                                                                        project
                                                                                            ?.indicator[
                                                                                        `indicator_year` +
                                                                                        yearIndex
                                                                                            ]
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        ) : null}
                                                                    </div>

                                                                    <div className="bg-white shadow sm:rounded-lg">

                                                                        {/* <SubTasks
                                                                                project={
                                                                                    project
                                                                                }
                                                                                quarter={
                                                                                    quarter
                                                                                }
                                                                            /> */}

                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />
                                </section>

                            </div>

                            <section
                                aria-labelledby="timeline-title"
                                className="lg:col-start-3 lg:col-span-1"
                            >
                                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mb-4">
                                    {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                    `PERCENTAGE` ||
                                    project?.indicator.indicator_umo?.name.toUpperCase() ===
                                    `NUMBER` ? (
                                        <div className="sm:col-span-1">
                                            <h2 className="text-lg font-medium text-gray-900 text-center">
                                                <strong>Annual Progress</strong>
                                            </h2>
                                            <div className="mt-1 text-center font-semibold text-3xl">
                                                {numberFormatter.format(
                                                    project.progress
                                                )}
                                                %
                                            </div>
                                            <div className="mt-1  text-gray-900">
                                                <div className="pt-2">
                                                    <div
                                                        className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                        <Bar
                                                            progressValue={
                                                                project.progress
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="pt-1 text-gray-900 text-sm">
                                                    <div className="flex justify-between mb-1">
                                                        <Status
                                                            progressValue={
                                                                project.progress
                                                            }
                                                        />
                                                        <Progress
                                                            progressValue={
                                                                project.progress
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : <span
                                        className="ml-1 header-tag-green"> SCORE TYPE:{" "}{project?.indicator.indicator_umo?.name.toUpperCase()}</span>}
                                </div>
                                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mb-4">
                                    <button
                                        type="button"
                                        className="button mb-2 w-full"
                                        onClick={() => {
                                            setEditableTIDInformation(project);
                                            setOpenAddEditTIDInformation(true);
                                        }}
                                    >
                                        TID Information
                                    </button>

                                    <button
                                        type="button"
                                        className="button mb-2 w-full"
                                        onClick={() => {
                                            setEditablePOE(project);
                                            setOpenAddEditPOE(true);
                                        }}
                                    >
                                        Project POE
                                    </button>

                                    <button
                                        type="button"
                                        className="button mb-2 w-full"
                                        onClick={() => {
                                            setEditablePOE(project);
                                            setOpenAddEditPOE(true);
                                        }}
                                    >
                                        Project Risk
                                    </button>

                                    <button
                                        type="button"
                                        onClick={() => {
                                            setEditableChallenges(project);
                                            setOpenAddEditChallenges(true);
                                        }}
                                        className={`button-default mb-2 w-full`}
                                    >
                                        Challenges
                                    </button>

                                    <button
                                        target="_blank"
                                        className={`button-default mb-2 w-full`}
                                        onClick={() => {
                                            setEditableComments(project);
                                            setOpenAddEditComments(true);
                                        }}
                                    >
                                        Comments
                                    </button>

                                    <NavLink
                                        to={`${PORTAL.TASKS_BY_PROJECT}/${project.id}`}
                                        className={`button-default mb-2 w-full`}
                                    >
                                        View Tasks ({project.total_tasks})
                                        <span className="button-icon m-0">
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    </NavLink>
                                </div>

                                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mb-4">
                                    <div className="bg-gray-100 p-4 rounded-md space-y-2">
                                        <h3 className="h3 font-semibold">
                                            Score Card: Quarterly Targets
                                        </h3>
                                        <em className="text-sm">
                                            KPI: {project.indicator.indicator_name}
                                        </em>
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-6">
                                                <label
                                                    className="text-sm"
                                                    htmlFor="score_type"
                                                >
                                                    Score Type:{" "}
                                                    <span className="text-red-600">
                                                *
                                            </span>
                                                </label>
                                            </div>
                                            <div className="col-span-6 text-sm">
                                                {project.indicator.indicator_umo.name}
                                            </div>
                                        </div>
                                        {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                        `PERCENTAGE` ||
                                        project?.indicator.indicator_umo?.name.toUpperCase() ===
                                        `NUMBER` ? <>

                                            <div className="grid grid-cols-12">
                                                <div className="col-span-6">
                                                    <label
                                                        className="text-sm"
                                                        htmlFor="baseline"
                                                    >
                                                        Baseline <br/>
                                                        (Previous Year):{" "}
                                                        <span className="text-red-600">
                                                *
                                            </span>
                                                    </label>
                                                </div>
                                                <div className="col-span-6 text-sm">
                                                    {project.indicator.baseline}
                                                    {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                    `PERCENTAGE`
                                                        ? `%`
                                                        : ``}
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-12">
                                                <div className="col-span-6">
                                                    <label
                                                        className="text-sm"
                                                        htmlFor="target"
                                                    >
                                                        Annual Target <br/>
                                                        (Current Year):{" "}
                                                        <span className="text-red-600">
                                                *
                                            </span>
                                                    </label>
                                                </div>
                                                <div className="col-span-6 text-sm">
                                                    {
                                                        project?.indicator[
                                                        `indicator_year` +
                                                        yearIndex
                                                            ]
                                                    }
                                                    {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                    `PERCENTAGE`
                                                        ? `%`
                                                        : ``}
                                                </div>
                                            </div>


                                            <>
                                                <hr/>
                                                {GLOBAL.QUARTERS.map((quarter, i) => (
                                                    <div
                                                        key={i}
                                                        className="grid grid-cols-12"
                                                    >
                                                        <div className="col-span-6">
                                                            <label
                                                                className="text-sm"
                                                                htmlFor={
                                                                    `quarter` +
                                                                    quarter +
                                                                    `_max`
                                                                }
                                                            >
                                                                Quarter {quarter} Max:
                                                            </label>
                                                        </div>
                                                        <div className="col-span-6 text-sm">
                                                            {
                                                                project
                                                                    .indicator[
                                                                `q` +
                                                                quarter
                                                                    ]
                                                            }
                                                            {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                            `PERCENTAGE`
                                                                ? `%`
                                                                : ""}


                                                            {project?.indicator.indicator_umo?.name.toUpperCase() ===
                                                            `PERCENTAGE`
                                                                ? ` (Measurable Target: ` +
                                                                project
                                                                    .indicator[
                                                                `q` +
                                                                quarter +
                                                                `_total`
                                                                    ] +
                                                                `)`
                                                                : ``}
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        </> : null}
                                    </div>
                                </div>
                                {/* <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                    <TaskActivity project_id={projectId} />
                                </div> */}
                            </section>
                        </div>
                    </>
                )
            ) : (
                <div className="mx-full px-8 my-4 text-center">
                    <Alert
                        type={`danger`}
                        message={`You do not have permission to view projects. Please contact the system administrator.`}
                    />
                </div>
            )}
        </>
    );
}
