import { GLOBAL } from "constants/global";

export default function Bar({ progressValue }) {
    return (
        <div
            className={`${
                GLOBAL.SLIDER.filter((x) =>
                    progressValue <= 100
                        ? x.MIN <= progressValue && x.MAX >= progressValue
                        : x.MAX > 100
                )[0].STYLE
            } h-2 rounded-full`}
            style={{ width: `${progressValue < 100 ? progressValue : 100}%` }}
        ></div>
    );
}
