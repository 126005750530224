import { useState } from "react";
import {
    DocumentChartBarIcon,
    UserIcon,
    UserPlusIcon,
} from "@heroicons/react/24/outline";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import { GLOBAL } from "constants/global";

export default function ViewUserDetails({ row }) {
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />

            <>
                {row.reporting_managers !== undefined &&
                    row.reporting_managers.length !== 0 && (
                        <>
                            <strong>Reporting Managers(s):</strong>{" "}
                            {row.reporting_managers.map((contact, i) => (
                                <span key={i}>
                                    <span
                                        className="hover:underline cursor-pointer"
                                        onClick={() => {
                                            setUserDetailsRow(contact);
                                            setOpenUserDetails(true);
                                        }}
                                    >
                                        {contact.first_name +
                                            " " +
                                            contact.last_name}
                                    </span>
                                    {i + 1 !==
                                        row?.normalised?.reporting_managers
                                            ?.length && ", "}
                                </span>
                            ))}
                        </>
                    )}

                {row.responsible_person !== undefined &&
                    row.responsible_person.length !== 0 && (
                        <>
                            <strong>Responsible Person:</strong>{" "}
                            {row.responsible_person.map((contact, i) => (
                                <span key={i}>
                                    <span
                                        className="hover:underline cursor-pointer"
                                        onClick={() => {
                                            setUserDetailsRow(contact);
                                            setOpenUserDetails(true);
                                        }}
                                    >
                                        {contact.first_name +
                                            " " +
                                            contact.last_name}
                                    </span>
                                    {i + 1 !==
                                        row?.normalised?.responsible_person
                                            ?.length && ", "}
                                </span>
                            ))}
                        </>
                    )}

                {row.responsible_managers !== undefined &&
                    row.responsible_managers.length !== 0 && (
                        <>
                            <strong>Responsible Managers:</strong>{" "}
                            {row.responsible_managers.map((contact, i) => (
                                <span key={i}>
                                    <span
                                        className="hover:underline cursor-pointer"
                                        onClick={() => {
                                            setUserDetailsRow(contact);
                                            setOpenUserDetails(true);
                                        }}
                                    >
                                        {contact.first_name +
                                            " " +
                                            contact.last_name}
                                    </span>
                                    {i + 1 !==
                                        row?.normalised?.responsible_managers
                                            ?.length && ", "}
                                </span>
                            ))}
                        </>
                    )}

                {row.performance_managers !== undefined &&
                    row.performance_managers.length !== 0 && (
                        <>
                            <strong>Performance Managers:</strong>{" "}
                            {row.performance_managers.map((contact, i) => (
                                <span key={i}>
                                    <span
                                        className="hover:underline cursor-pointer"
                                        onClick={() => {
                                            setUserDetailsRow(contact);
                                            setOpenUserDetails(true);
                                        }}
                                    >
                                        {contact.first_name +
                                            " " +
                                            contact.last_name}
                                    </span>
                                    {i + 1 !==
                                        row?.normalised?.performance_managers
                                            ?.length && ", "}
                                </span>
                            ))}
                        </>
                    )}
            </>
        </>
    );
}
