import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isAfter } from "date-fns";

export default function AddRemoveMultipleDeliverable({
    formDetails,
    setFormDetails,
}) {
    const [multiInputFields, setMultiInputFields] = useState([]);

    const addInputField = () => {
        setMultiInputFields([
            ...multiInputFields,
            {
                deliverable_date: new Date(formDetails.end_date),
                deliverable_description: "",
            },
        ]);
    };

    const removeInputFields = (index) => {
        const rows = [...multiInputFields];
        rows.splice(index, 1);
        setMultiInputFields(rows);
        setFormDetails((prevState) => ({
            ...prevState,
            [`deliverables`]: rows,
        }));
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target ? event.target : event;
        const list = [...multiInputFields];
        list[index][name] = value;
        setMultiInputFields(list);
        setFormDetails((prevState) => ({
            ...prevState,
            [`deliverables`]: list,
        }));
    };

    useEffect(() => {
        if (
            multiInputFields.length === 0 &&
            formDetails?.end_date !== undefined
        )
            addInputField();

        if (
            formDetails.deliverables !== undefined &&
            formDetails.deliverables.length !== 0
        )
            setMultiInputFields(formDetails.deliverables);
    }, [formDetails]);

    return (
        <>
            {multiInputFields.map((data, index) => {
                const { deliverable_date, deliverable_description } = data;
                return (
                    <div
                        key={index}
                        className="space-y-2 border-b border-gray-200 mb-2 pb-2"
                    >
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="deliverable_date"
                                >
                                    Next deliverable date:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    id="deliverable_date"
                                    name="deliverable_date"
                                    autoComplete="deliverable_date"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    minDate={new Date(formDetails.start_date)}
                                    maxDate={new Date(formDetails.end_date)}
                                    dateFormat="dd MMMM yyyy"
                                    selected={
                                        isAfter(
                                            new Date(deliverable_date),
                                            new Date(formDetails.end_date)
                                        )
                                            ? new Date(formDetails.end_date)
                                            : new Date(deliverable_date)
                                    }
                                    onChange={(event) => {
                                        handleChange(index, {
                                            name: `deliverable_date`,
                                            value: event,
                                        });
                                    }}
                                    className={`form-field`}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="deliverable_description"
                                >
                                    Deliverable description:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="deliverable_description"
                                    name="deliverable_description"
                                    autoComplete="deliverable_description"
                                    className={`form-field`}
                                    onChange={(event) =>
                                        handleChange(index, event)
                                    }
                                    value={
                                        deliverable_description
                                            ? deliverable_description
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>
                        {index !== 0 ? (
                            <div
                                className="button-default"
                                onClick={() => removeInputFields(index)}
                            >
                                Remove
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                );
            })}
            <div className="flex justify-center">
                <div
                    className="button-default mx-1"
                    onClick={() => addInputField()}
                >
                    Add Another Deliverable
                </div>
            </div>
        </>
    );
}
