import { useEffect, useState, useContext } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import PageHeader from "components/page-header";
import Table from "components/table";
import { sub, add, compareDesc, isAfter } from "date-fns";
import { useTextFormatHook } from "hooks/text-formatter";
import { useDataLoaderHook } from "hooks/data-loader";
import { ApiConsumer } from "api/ApiConsumer";
import { NavLink } from "react-router-dom";
import { GLOBAL } from "constants/global";
import { API_ROUTES } from "routes/api";
import { PORTAL } from "routes/portal";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppLoader } from "components/loader/app-loader";
import { AppLoaderContext } from "contexts/app-loader";

export default function TaskReports() {
    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    let { loadMultiple } = useDataLoaderHook();
    const [formDetails, setFormDetails] = useState({});
    const [kpas, setKpas] = useState([]);
    const [sections, setSections] = useState([]);
    const [projects, setProjects] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [responsiblePerson, setresponsiblePerson] = useState([]);
    const [projectsReport, setProjectsReport] = useState([]);

    const addTaskLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.TASK}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const searchReport = () => {
        setLoading(true);
        for (const key in formDetails) {
            if (
                Array.isArray(formDetails[key]) &&
                formDetails[key].length === 0
            ) {
                delete formDetails[key];
            }
        }
        ApiConsumer.post(API_ROUTES.REPORT.TASKS, formDetails)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                //Group by "id" to avoid duplicates
                res.data = res.data.filter(
                    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                );
                //Filter by status
                /* res.data.map((row, i) => {
                    if (
                        isAfter(new Date(), new Date(row.end_date)) &&
                        row.overall_progress < 100
                    )
                        res.data[i]["status"] = "OVERDUE".toLowerCase();
                    else
                        res.data[i]["status"] = GLOBAL.SLIDER.filter((x) =>
                            row.overall_progress <= 100
                                ? x.MIN <= row.overall_progress &&
                                  x.MAX >= row.overall_progress
                                : x.MAX > 100
                        )[0].NAME.toLowerCase();
                }); */
                if (
                    formDetails.task_status !== undefined &&
                    formDetails.task_status.length !== 0
                )
                    res.data = res.data.filter(
                        (report) =>
                            formDetails.task_status.indexOf(report.status) !==
                            -1
                    );

                let normalisedProjectData = normaliseTableData(
                    GLOBAL.TASK_HEADERS,
                    res.data
                );
                setProjectsReport(
                    addTaskLinksToTableData(normalisedProjectData)
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onProjectDateChange = (dates) => {
        const [start, end] = dates;

        setFormDetails((prevState) => ({
            ...prevState,
            [`project_start_date`]: start,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_end_date`]: end,
        }));
    };

    useEffect(() => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_start_date`]: sub(new Date(), { years: 1 }),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_end_date`]: add(new Date(), { years: 1 }),
        }));
    }, []);

    useEffect(() => {
        loadMultiple(API_ROUTES.CONTENT.KPA, setKpas);
        loadMultiple(API_ROUTES.SECTION.SECTIONS, setSections);
        loadMultiple(API_ROUTES.PROJECT.PROJECTS, setProjects);
        loadMultiple(API_ROUTES.SECTION.DEPARTMENTS, setDepartments);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let userOptions = res.data.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setresponsiblePerson(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });

        return () => {
            setDepartments([]);
            setresponsiblePerson([]);
        };
    }, []);

    const clearSearch = () => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`kpas`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`sections`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`projects`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`responsible_person`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`task_status`]: [],
        }));
        setProjectsReport([]);
    };

    return (
        <>
            <PageHeader pageHeaderName={`Task Reports`} />

            <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                <div className="grid grid-cols-12 space-x-4">
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="kpas">
                            KPAs:
                        </label>
                        <Select
                            id="kpas"
                            name="kpas"
                            placeholder="All kpas"
                            isMulti
                            options={kpas}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`kpas`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(kpas).length !== 0 &&
                                formDetails.kpas !== undefined
                                    ? formDetails?.kpas?.map(
                                          (selectedOption) => {
                                              return kpas[
                                                  kpas.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="sections">
                            Select sections:
                        </label>
                        <Select
                            id="sections"
                            name="sections"
                            placeholder="All sections"
                            isMulti
                            options={sections}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`sections`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(sections).length !== 0 &&
                                formDetails.sections !== undefined
                                    ? formDetails?.sections?.map(
                                          (selectedOption) => {
                                              return sections[
                                                  sections.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                    <div className="col-span-6">
                        <label className="text-sm" htmlFor="projects">
                            Select projects:
                        </label>
                        <Select
                            id="projects"
                            name="projects"
                            placeholder="All projects"
                            isMulti
                            options={projects}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`projects`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(projects).length !== 0 &&
                                formDetails.projects !== undefined
                                    ? formDetails?.projects?.map(
                                          (selectedOption) => {
                                              return projects[
                                                  projects.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="border-b border-gray-200 mx-full px-4 sm:px-6 lg:px-8 my-3 pb-3">
                <div className="grid grid-cols-12 space-x-4">
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="departments">
                            Departments:
                        </label>
                        <Select
                            id="departments"
                            name="departments"
                            placeholder="All departments"
                            isMulti
                            options={departments}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`departments`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(departments).length !== 0 &&
                                formDetails.departments !== undefined
                                    ? formDetails?.departments?.map(
                                          (selectedOption) => {
                                              return departments[
                                                  departments.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>

                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="responsible_person">
                            Responsible person(s):
                        </label>
                        <Select
                            id="responsible_person"
                            name="responsible_person"
                            placeholder="All users"
                            isMulti
                            options={responsiblePerson}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`responsible_person`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(responsiblePerson).length !== 0 &&
                                formDetails.responsible_person !== undefined
                                    ? formDetails?.responsible_person?.map(
                                          (selectedOption) => {
                                              return responsiblePerson[
                                                  responsiblePerson.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="task_status">
                            Project status:
                        </label>
                        <Select
                            id="task_status"
                            name="task_status"
                            placeholder="All statuses"
                            isMulti
                            options={GLOBAL.STATUS}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`task_status`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(GLOBAL.STATUS).length !== 0 &&
                                formDetails.task_status !== undefined
                                    ? formDetails?.task_status?.map(
                                          (selectedOption) => {
                                              return GLOBAL.STATUS[
                                                  GLOBAL.STATUS.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="relative py-2">
                <AppLoader
                    pageContent={
                        <h2 className="flex mx-full px-4 sm:px-6 lg:px-8 text-lg leading-6 text-gray-900 text-left items-center">
                            <span className="min-w-0 flex-1 items-center font-medium">
                                Task Results ({projectsReport.length})
                                <br />
                                <span
                                    className="brand-link text-sm font-normal"
                                    onClick={() => clearSearch()}
                                >
                                    Clear all search parameters
                                </span>
                            </span>

                            <div className="col-span-3">
                                <label className="text-sm" htmlFor="date_range">
                                    Date range:
                                </label>
                                <DatePicker
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.project_start_date}
                                    onChange={(event) =>
                                        onProjectDateChange(event)
                                    }
                                    className="form-field"
                                    startDate={formDetails.project_start_date}
                                    endDate={formDetails.project_end_date}
                                    selectsRange
                                />
                            </div>
                            <button
                                type="button"
                                className="button flex-shrink-0 ml-2 mt-5"
                                onClick={() => searchReport()}
                            >
                                <div
                                    className="flex items-center pointer-events-none mr-1"
                                    aria-hidden="true"
                                >
                                    <MagnifyingGlassIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </div>
                                Search
                            </button>
                        </h2>
                    }
                />
            </div>
            <Table
                tableTitle={`Project Report Results`}
                tableHeaders={GLOBAL.TASK_HEADERS}
                tableData={projectsReport}
                fontSize="text-xs"
            />
        </>
    );
}
