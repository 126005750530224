import { useState, useEffect } from "react";

export default function AddRemoveMultipleGoals({
    formDetails,
    setFormDetails,
}) {
    const [multiInputFields, setMultiInputFields] = useState([
        {
            strategic_goal: "",
            goal_statement: "",
            goal_outcome: "",
        },
    ]);

    const addInputField = () => {
        setMultiInputFields([
            ...multiInputFields,
            {
                strategic_goal: "",
                goal_statement: "",
                goal_outcome: "",
            },
        ]);
    };

    const removeInputFields = (index) => {
        const rows = [...multiInputFields];
        rows.splice(index, 1);
        setMultiInputFields(rows);
        setFormDetails((prevState) => ({
            ...prevState,
            [`goals`]: rows,
        }));
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const list = [...multiInputFields];
        list[index][name] = value;
        setMultiInputFields(list);
        setFormDetails((prevState) => ({
            ...prevState,
            [`goals`]: list,
        }));
    };

    useEffect(() => {
        if (formDetails.goals !== undefined && formDetails.goals.length !== 0)
            setMultiInputFields(formDetails.goals);
    }, [formDetails]);

    return (
        <>
            {multiInputFields.map((data, index) => {
                const { strategic_goal, goal_statement, goal_outcome } = data;
                return (
                    <div
                        key={index}
                        className="space-y-2 border-b border-gray-200 mb-2 pb-2"
                    >
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="strategic_goal"
                                >
                                    Strategic goal/objective:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="strategic_goal"
                                    name="strategic_goal"
                                    type="text"
                                    autoComplete="strategic_goal"
                                    className={`form-field`}
                                    onChange={(event) => {
                                        handleChange(index, event);
                                    }}
                                    value={strategic_goal ? strategic_goal : ""}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="goal_statement"
                                >
                                    Goal statement:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="goal_statement"
                                    name="goal_statement"
                                    autoComplete="goal_statement"
                                    className={`form-field`}
                                    onChange={(event) =>
                                        handleChange(index, event)
                                    }
                                    value={goal_statement ? goal_statement : ""}
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="goal_outcome"
                                >
                                    Goal outcome:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="goal_outcome"
                                    name="goal_outcome"
                                    type="text"
                                    autoComplete="goal_outcome"
                                    className={`form-field`}
                                    onChange={(event) =>
                                        handleChange(index, event)
                                    }
                                    value={goal_outcome ? goal_outcome : ""}
                                />
                            </div>
                        </div>

                        {index !== 0 ? (
                            <div
                                className="button-default"
                                onClick={() => removeInputFields(index)}
                            >
                                Remove
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                );
            })}
            <div className="flex justify-center">
                <div
                    className="button-default mx-1"
                    onClick={() => addInputField()}
                >
                    Add Another Goal/Objective
                </div>
            </div>
        </>
    );
}
