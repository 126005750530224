import { GLOBAL } from "constants/global";

export default function Progress({ progressValue }) {
    const numberFormatter = new Intl.NumberFormat("en-US");
    return (
        <span>
            Progress{" "}
            <span
                className={`inline-flex uppercase items-center px-2 py-0.5 rounded text-xs font-medium tag ${
                    GLOBAL.SLIDER.filter((x) =>
                        progressValue <= 100
                            ? x.MIN <= progressValue && x.MAX >= progressValue
                            : x.MAX > 100
                    )[0].STYLE
                } text-white`}
            >
                {numberFormatter.format(progressValue)}%
            </span>{" "}
        </span>
    );
}
