import { Routes, Route, Outlet } from "react-router-dom";
import { PORTAL } from "routes/portal";
import Sections from "pages/sections";

export default function SectionRoutes() {
    return (
        <>
            <Routes>
                <Route index element={<Sections />} />
                <Route
                    path={
                        PORTAL.SECTIONS_BY_KPA.replace(PORTAL.SECTIONS, "") +
                        `/:kpa_id`
                    }
                    element={<Sections />}
                />
                <Route
                    path={
                        PORTAL.SECTIONS_BY_DEPARTMENT.replace(
                            PORTAL.SECTIONS,
                            ""
                        ) + `/:department_id`
                    }
                    element={<Sections />}
                />
                <Route
                    path={
                        PORTAL.SECTIONS_BY_TYPE.replace(PORTAL.SECTIONS, "") +
                        `/:type_id`
                    }
                    element={<Sections />}
                />
                <Route
                    path={
                        PORTAL.SECTIONS_BY_USER.replace(PORTAL.SECTIONS, "") +
                        `/:user_id`
                    }
                    element={<Sections />}
                />
            </Routes>
            <Outlet />
        </>
    );
}
