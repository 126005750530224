import { Fragment, useState, useEffect, useContext } from "react";
import { format, isAfter } from "date-fns";
import { useFormValidation } from "hooks/form-validation";
import { useDataLoaderHook } from "hooks/data-loader";
import { API_ROUTES } from "routes/api";
import { ApiConsumer } from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import { GLOBAL } from "constants/global";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import Select from "react-select";
import { AppLoaderContext } from "contexts/app-loader";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";

export default function AddEditKpiIndicator({
    year,
    yearIndex,
    kpa,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    let { loadDropdown, loadMultiple } = useDataLoaderHook();

    const [kpas, setKpas] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [responsibleManagers, setResponsibleManagers] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        { name: "planning_level", display: "planning level" },
        { name: "q1", display: "quarter 1 target" },
        { name: "q2", display: "quarter 2 target" },
        { name: "q3", display: "quarter 3 target" },
        { name: "q4", display: "quarter 4 target" },
        { name: "departments", display: "department / division" },
        { name: "responsible_managers", display: "responsible manager(s)" },
    ];

    const validateAddEditIndicators = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            ) &&
            validateScoreCard()
        ) {
            console.log("DETAILS");
            console.log(formDetails);
            setLoading(true);

            ApiConsumer.put(
                API_ROUTES.CONTENT.KPI_INDICATOR(editableRow.id),
                formDetails
            )
                .then((res) => {
                    console.log("RESP");
                    console.log(res.data);
                    setRowUpdated(true);
                    setFormSubmitted(true);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    setFormError(err.toString());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        loadDropdown(API_ROUTES.CONTENT.KPA, setKpas);
        loadMultiple(API_ROUTES.SECTION.DEPARTMENTS, setDepartments);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (user) =>
                        [
                            GLOBAL.USER_TYPES.STAFF_MEMBER,
                            GLOBAL.USER_TYPES.ADMINISTRATOR,
                        ].indexOf(user.user_type.name) !== -1
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setResponsibleManagers(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, []);

    useEffect(() => {
        /* Defaults */

        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator`]: editableRow?.id,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`year`]: year,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`planning_level`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`measurable_objective`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`evidence`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`baseline`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`q1`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`q1_total`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`q2`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`q2_total`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`q3`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`q3_total`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`q4`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`q4_total`]: "",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`responsible_managers`]: [],
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator`]: editableRow?.id,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`planning_level`]: editableRow?.planning_level,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`measurable_objective`]: editableRow?.measurable_objective,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`evidence`]: editableRow?.evidence,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`baseline`]: editableRow?.baseline,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q1`]: editableRow?.q1,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q1_total`]: editableRow?.q1_total,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q2`]: editableRow?.q2,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q2_total`]: editableRow?.q2_total,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q3`]: editableRow?.q3,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q3_total`]: editableRow?.q3_total,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q4`]: editableRow?.q4,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q4_total`]: editableRow?.q4_total,
            }));

            if (editableRow?.departments !== null)
                setFormDetails((prevState) => ({
                    ...prevState,
                    [`departments`]: JSON.parse(
                        editableRow?.departments?.replace(/'/g, '"')
                    ),
                }));
            if (editableRow?.responsible_managers !== null)
                setFormDetails((prevState) => ({
                    ...prevState,
                    [`responsible_managers`]: JSON.parse(
                        editableRow?.responsible_managers?.replace(/'/g, '"')
                    ),
                }));
        }

        return () => {
            setKpas([]);
            setResponsibleManagers([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    const validateScoreCard = function () {
        let annualTotal =
            parseFloat(formDetails.q1) +
            parseFloat(formDetails.q2) +
            parseFloat(formDetails.q3) +
            parseFloat(formDetails.q4);

        if (
            editableRow?.indicator_umo?.name.toUpperCase() === `PERCENTAGE` ||
            editableRow?.indicator_umo?.name.toUpperCase() === `NUMBER`
        ) {
            if (
                annualTotal >
                parseFloat(editableRow[`indicator_year` + yearIndex])
            ) {
                setFormError(
                    "Please ensure total of all the quarters does not exceed the Annual Target."
                );
                return false;
            } else {
                setFormError("");
                return true;
            }
        } else return true;
    };

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditIndicators(
                            Object.keys(editableRow).length
                        );
                    }}
                    method="POST"
                >
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="kpa">
                                    <strong>KPA:</strong>
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                <strong>{kpa?.name}</strong>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="kpa">
                                    KPI (indicator):
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                {editableRow?.indicator_name}
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="planning_level"
                                >
                                    Planning Level{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="planning_level"
                                    name="planning_level"
                                    autoComplete="planning_level"
                                    className={`${
                                        invalidFormDetails.planning_level
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`planning_level`]:
                                                event.target.value,
                                        }))
                                    }
                                    value={
                                        formDetails[`planning_level`]
                                            ? formDetails[`planning_level`]
                                            : ""
                                    }
                                >
                                    <option value="selected">
                                        Select Planning Level
                                    </option>
                                    <option value="Outcome">Outcome</option>
                                    <option value="Output">Output</option>
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="measurable_objective"
                                >
                                    Measurable Objective:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="measurable_objective"
                                    name="measurable_objective"
                                    autoComplete="measurable_objective"
                                    className={`form-field ${
                                        invalidFormDetails.measurable_objective
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`measurable_objective`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.measurable_objective
                                            ? formDetails.measurable_objective
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="evidence">
                                    Evidence:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="evidence"
                                    name="evidence"
                                    autoComplete="evidence"
                                    className={`form-field ${
                                        invalidFormDetails.evidence
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`evidence`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.evidence
                                            ? formDetails.evidence
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div>
                            <div className="grid grid-cols-12 mt-4 pt-4 text-sm font-semibold border-t border-gray-200">
                                <div className="col-span-4">
                                    <label className="text-sm" htmlFor="kpa">
                                        Indicator Unit Of Measurement (UOM):
                                    </label>
                                </div>
                                <div className="col-span-8 text-sm">
                                    {editableRow?.indicator_umo?.name}
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="baseline">
                                    Baseline:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="baseline"
                                    name="baseline"
                                    type="text"
                                    autoComplete="baseline"
                                    className={`form-field ${
                                        invalidFormDetails.baseline
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`baseline`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.baseline
                                            ? formDetails.baseline
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div>
                            <div className="grid grid-cols-12 mt-4 pt-4 text-sm font-semibold border-t border-gray-200">
                                <div className="col-span-4">
                                    <label
                                        className="text-sm"
                                        htmlFor="baseline"
                                    >
                                        Annual Target:
                                    </label>
                                </div>
                                <div className="col-span-8">
                                    {editableRow[`indicator_year` + yearIndex]}
                                    {editableRow?.indicator_umo?.name.toUpperCase() ===
                                        `PERCENTAGE` && `%`}
                                </div>
                            </div>
                        </div>

                        {GLOBAL.QUARTERS.map((quarter, i) => (
                            <div key={i}>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-4">
                                        <label
                                            className="text-sm"
                                            htmlFor={`q` + quarter}
                                        >
                                            Quarter {quarter} Target{" "}
                                            {editableRow?.indicator_umo?.name.toUpperCase() ===
                                                `PERCENTAGE` && `%`}
                                            :{" "}
                                            <span className="text-red-600">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-span-8">
                                        <input
                                            id={`q` + quarter}
                                            name={`q` + quarter}
                                            type="text"
                                            autoComplete={`q` + quarter}
                                            className={`form-field ${
                                                invalidFormDetails[
                                                    `q` + quarter
                                                ]
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) =>
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`q` + quarter]:
                                                        event.target.value,
                                                }))
                                            }
                                            value={
                                                formDetails[`q` + quarter]
                                                    ? formDetails[`q` + quarter]
                                                    : ""
                                            }
                                        />
                                    </div>
                                </div>
                                {editableRow?.indicator_umo?.name.toUpperCase() ===
                                    `PERCENTAGE` && (
                                    <div className="grid grid-cols-12 bg-gray-100 p-4 rounded-md mt-2">
                                        <div className="col-span-4">
                                            <label
                                                className="text-sm"
                                                htmlFor={
                                                    `q` + quarter + `_total`
                                                }
                                            >
                                                Quarter {quarter} Measurable
                                                Target: (denominator)
                                            </label>
                                        </div>
                                        <div className="col-span-8">
                                            <input
                                                id={`q` + quarter + `_total`}
                                                name={`q` + quarter + `_total`}
                                                type="text"
                                                autoComplete={
                                                    `q` + quarter + `_total`
                                                }
                                                className={`form-field ${
                                                    invalidFormDetails[
                                                        `q` + quarter + `_total`
                                                    ]
                                                        ? `border-red-600`
                                                        : `border-gray-300`
                                                }`}
                                                onChange={(event) =>
                                                    setFormDetails(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            [`q` +
                                                            quarter +
                                                            `_total`]:
                                                                event.target
                                                                    .value,
                                                        })
                                                    )
                                                }
                                                value={
                                                    formDetails[
                                                        `q` + quarter + `_total`
                                                    ]
                                                        ? formDetails[
                                                              `q` +
                                                                  quarter +
                                                                  `_total`
                                                          ]
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}

                        <div>
                            <p className="mt-4 pt-4 text-sm font-semibold border-t border-gray-200">
                                Accountability
                            </p>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="departments"
                                >
                                    Department / Division:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="departments"
                                    name="departments"
                                    isMulti
                                    options={departments}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.departments
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`departments`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(departments).length !== 0
                                            ? formDetails?.departments?.map(
                                                  (selectedOption) => {
                                                      return departments[
                                                          departments.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="responsible_managers"
                                >
                                    Responsible manager(s):{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="responsible_managers"
                                    name="responsible_managers"
                                    isMulti
                                    options={responsibleManagers}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.responsible_managers
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`responsible_managers`]: [
                                                ...event,
                                            ].map((option) => option.value),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(responsibleManagers)
                                            .length !== 0
                                            ? formDetails?.responsible_managers?.map(
                                                  (selectedOption) => {
                                                      return responsibleManagers[
                                                          responsibleManagers.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {`Update SDBIP Targets`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully updated indicators.`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
