export default function BarQuarters({
    progressValue,
    progressMax,
    progressTarget,
}) {
    const SLIDER = [
        {
            NAME: progressValue === null ? "NOT STARTED" : "POOR",
            MIN: 0,
            MAX: progressMax / 4,
            STYLE: "bg-red-500",
        },
        {
            NAME: "PROGRESSIVE",
            MIN: progressMax / 4,
            MAX: progressMax / 4 + progressMax / 2,
            STYLE: "bg-orange-500",
        },
        {
            NAME: "GOOD",
            MIN: progressMax / 4 + progressMax / 2,
            MAX: progressMax - 1,
            STYLE: "bg-yellow-500",
        },
        {
            NAME: "TARGET ACHIEVED",
            MIN: progressMax - 1,
            MAX: progressMax,
            STYLE: "bg-green-500",
        },
        {
            NAME: "EXCEEDED",
            MIN: progressMax,
            MAX: progressTarget,
            STYLE: "bg-green-800",
        },
    ];

    return (
        <>
            <div className="bg-gray-200 rounded-full">
                <div
                    className={`${
                        progressValue === null
                            ? `bg-gray-200`
                            : SLIDER.filter(
                                  (x) =>
                                      x.MIN <= progressValue &&
                                      x.MAX >= progressValue
                              )[0].STYLE
                    } h-2 rounded-full`}
                    style={{
                        width: `${progressValue * (100 / progressTarget)}%`,
                    }}
                ></div>
            </div>
            <div className="text-xs text-center">
                {
                    SLIDER.filter(
                        (x) => x.MIN <= progressValue && x.MAX >= progressValue
                    )[0].NAME
                }
            </div>
        </>
    );
}
