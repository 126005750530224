import { Routes, Route, Outlet } from "react-router-dom";
import { PORTAL } from "routes/portal";
import Tasks from "pages/tasks";
import Task from "pages/task";

export default function TaskRoutes() {
    return (
        <>
            <Routes>
                <Route index element={<Tasks />} />
                <Route
                    path={
                        PORTAL.TASKS_BY_PROJECT.replace(PORTAL.TASKS, "") +
                        `/:project_id`
                    }
                    element={<Tasks />}
                />
                <Route
                    path={
                        PORTAL.TASKS_BY_USER.replace(PORTAL.TASKS, "") +
                        `/:user_id`
                    }
                    element={<Tasks />}
                />
                <Route
                    path={PORTAL.TASK.replace(PORTAL.TASKS, "") + `/:task_id`}
                    element={<Task />}
                />
            </Routes>
            <Outlet />
        </>
    );
}
